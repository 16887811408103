<script>
import { computed, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { useFetch } from "@vueuse/core";
export default {
  name: "AppLayoutPage",
  setup() {
    const store = useStore();
    const route = useRoute();

    const getItemData = computed(() => store.getters.getItemData);
    const sideBarMenuStore = computed(() => store.getters.getSideBarMenu);
    const type = computed(() => route.params.type);

    function fetchMenu(slug) {
      let id;
      switch (slug) {
        case "sport":
          id = 2;
          break;
        case "vacation":
          id = 299;
          break;
        case "management":
          id = 335;
          break;
      }
      let url = "https://admin.dss-sport.ru/wp-json/wp/v2/pages/" + id;
      const { data } = useFetch(url, { refetch: true }).get().json();
      store.commit("SET_SIDE_BAR_MENU", {
        sideBarMenu: data,
      });
    }

    onMounted(() => {
      fetchMenu(type.value);
    });

    watch(
      () => route.params.type,
      async () => {
        try {
          fetchMenu(type.value);
        } catch (e) {
          console.error(e);
        }
      }
    );

    return {
      sideBarMenuStore,
      getItemData,
      type,
    };
  },
};
</script>

<template>
  <div class="layout page-layout">
    <div class="sidebar">
      <h3 class="sidebar__title" v-if="type === 'sport'">спорт</h3>
      <h3 class="sidebar__title" v-else-if="type === 'vacation'">отдых</h3>
      <h3 class="sidebar__title" v-else-if="type === 'management'">дирекция</h3>
      <ul class="sidebar__menu-list">
        <template v-for="item in sideBarMenuStore?.childrens" :key="item?.id">
          <li class="sidebar__menu-item" v-if="item.id != 353">
            <router-link
              class="sidebar__menu-link"
              :to="{
                name: 'Page',
                params: { type: type, slug: item?.id },
              }"
            >
              <span class="sidebar__menu-link--title">{{ item?.title }} </span>
              <span
                v-if="item?.acf?.description"
                class="sidebar__menu-link--subtitle"
                >{{ item?.acf?.description }}</span
              >
            </router-link>
          </li>
          <li class="sidebar__menu-item" v-if="item.id === 353">
            <router-link
              class="sidebar__menu-link"
              :to="{ name: 'Reception', params: { type: 'management' } }"
            >
              <span class="sidebar__menu-link--title">Интернет-приёмная</span>
            </router-link>
          </li>
        </template>
      </ul>
    </div>
    <div class="page-block">
      <slot />
    </div>
  </div>
</template>

<style lang="sass" scoped>
@import "../styles/scss/_mixins.scss"

.page-layout
    width: 100%
    display: grid
    grid-template-columns: 340px 1fr
    gap: $default-padding*1.2
    @media only screen and (max-width: $sizes-md)
      display: flex
      flex-direction: column
    & .sidebar
      padding: $default-padding*1.9
      background: #f1f1f1
      &__title
        font-size: $base-font-size*2
        color: $primary-color
        font-weight: 800
        text-transform: uppercase
        margin: 0
      &__menu-list
        list-style: none
        padding: 0
        margin-top: $default-padding*1.5
      &__menu-link
        @include d-flex(column, flex-start, flex-start, null, null, 5px)
        margin-bottom: $default-padding*1.2
        position: relative
        &.router-link-active
          color: $primary-color
          &::before
            position: absolute
            top: 0
            left: -($default-padding*1.9)
            height: 100%
            content: ''
            width: 6px
            background: $primary-color
            border-radius: 0px 3px 3px 0px
          & .sidebar__menu-link--title
            font-weight: 800
        &--title
          font-weight: 700
          font-size: $base-font-size*.8
        &--subtitle
          font-size: $base-font-size*.8
</style>
