import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import AppLayout from "@/layouts/AppLayout";
import PerfectScrollbar from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";

import "./styles/normalize.css";
import "./styles/scss/main.scss";

createApp(App)
  .use(store)
  .use(router)
  .use(PerfectScrollbar)
  .component("AppLayout", AppLayout)
  .mount("#app");
