<template>
  <component :is="layout">
    <slot />
  </component>
  <NewsDrawer />
</template>

<script>
import { shallowRef, watch } from "vue";
import { useRoute } from "vue-router";
import AppLayoutDefault from "./AppLayoutDefault";
import NewsDrawer from "@/components/NewsDrawer.vue";
export default {
  name: "AppLayout",
  components: {
    NewsDrawer,
  },
  provide: {
    test: false,
  },
  setup() {
    const layout = shallowRef(AppLayoutDefault);
    const route = useRoute();
    watch(
      () => route.meta,
      async (meta) => {
        try {
          const component = await require(`@/layouts/${meta.layout}.vue`);
          layout.value = component?.default || AppLayoutDefault;
        } catch (e) {
          layout.value = AppLayoutDefault;
        }
      }
    );
    return { layout };
  },
};
</script>
