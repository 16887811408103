<script>
export default {
  name: "icon-news",
  props: {
    iconColor: {
      type: String,
      default: "rgb(20.392157%, 59.607846%, 85.882354%)",
    },
  },
  setup() {},
};
</script>
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 50 50"
    width="50px"
    height="50px"
  >
    <g id="surface154139604">
      <path
        style="stroke: none; fill-rule: nonzero; fill-opacity: 1"
        :fill="iconColor"
        d="M 0 9 L 0 11 L 50 11 L 50 9 Z M 0 24 L 0 26 L 50 26 L 50 24 Z M 0 39 L 0 41 L 50 41 L 50 39 Z M 0 39 "
      />
    </g>
  </svg>
</template>
