<script>
export default {
  name: "icon-bow",
  props: {
    iconColor: {
      type: String,
      default: "#333333",
    },
  },
  setup() {},
};
</script>
<template>
  <svg
    width="61"
    height="60"
    viewBox="0 0 61 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.1303 46.7513C15.9171 46.7513 8.4552 39.2132 8.4552 30.0761C8.4552 20.8629 15.9171 13.401 25.1303 13.401C32.44 13.401 38.8359 18.1218 41.044 25.1269C41.1963 25.5837 40.8917 26.1167 40.4349 26.1929C39.978 26.3452 39.445 26.0406 39.3689 25.5837C37.3892 19.3401 31.6785 15.1523 25.1303 15.1523C16.907 15.1523 10.2065 21.8528 10.2065 30.0761C10.2065 38.2995 16.907 45 25.1303 45C31.7547 45 37.6938 40.5076 39.445 34.1878C39.5973 33.731 40.0542 33.4264 40.511 33.5787C40.9679 33.731 41.2725 34.1878 41.1202 34.6447C39.1405 41.7259 32.5161 46.7513 25.1303 46.7513Z"
      :fill="iconColor"
    />
    <path
      d="M25.1303 41.1929C19.0389 41.1929 14.0135 36.2436 14.0135 30.0761C14.0135 23.9847 18.9628 18.9594 25.1303 18.9594C31.2217 18.9594 36.2471 23.9086 36.2471 30.0761C36.2471 36.1675 31.2217 41.1929 25.1303 41.1929ZM25.1303 20.7106C19.9526 20.7106 15.7648 24.8984 15.7648 30.0761C15.7648 35.2538 19.9526 39.4416 25.1303 39.4416C30.308 39.4416 34.4958 35.2538 34.4958 30.0761C34.4958 24.8984 30.308 20.7106 25.1303 20.7106Z"
      :fill="iconColor"
    />
    <path
      d="M25.1303 35.6345C22.0846 35.6345 19.5719 33.1218 19.5719 30.0761C19.5719 27.0304 22.0846 24.5177 25.1303 24.5177C28.176 24.5177 30.6887 27.0304 30.6887 30.0761C30.6887 33.1218 28.176 35.6345 25.1303 35.6345ZM25.1303 26.269C23.0744 26.269 21.3232 27.9441 21.3232 30.0761C21.3232 32.2081 22.9983 33.8832 25.1303 33.8832C27.2623 33.8832 28.9374 32.2081 28.9374 30.0761C28.9374 27.9441 27.1861 26.269 25.1303 26.269Z"
      :fill="iconColor"
    />
    <path
      d="M40.8157 30.9137H25.1304C24.6735 30.9137 24.2167 30.5329 24.2167 30.0761C24.2167 29.6192 24.5974 29.2385 25.1304 29.2385H40.8157C41.2725 29.2385 41.7294 29.6192 41.7294 30.0761C41.7294 30.5329 41.2725 30.9137 40.8157 30.9137Z"
      :fill="iconColor"
    />
    <path
      d="M50.7141 35.2537H39.2928C39.0644 35.2537 38.7598 35.1015 38.6075 34.9492L34.9527 30.4568C34.7243 30.1522 34.7243 29.6954 34.9527 29.3147L38.6075 24.8223C38.7598 24.5938 38.9882 24.5177 39.2928 24.5177H50.7903C51.0948 24.5177 51.3994 24.7461 51.5517 25.0507C51.704 25.3553 51.6278 25.736 51.4755 25.9644L48.2776 29.9238L51.4755 33.8832C51.704 34.1116 51.704 34.4923 51.5517 34.7969C51.3994 35.0253 51.0948 35.2537 50.7141 35.2537ZM39.6735 33.5025H48.8867L46.374 30.4568C46.1456 30.1522 46.1456 29.6954 46.374 29.3147L48.8867 26.269H39.6735L36.704 29.8477L39.6735 33.5025Z"
      :fill="iconColor"
    />
  </svg>
</template>
