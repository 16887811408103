<template>
  <div class="layout">
    <slot />
  </div>
</template>

<script>
export default {
  name: "AppLayoutDefault",
};
</script>
