<script>
export default {
  name: "icon-running",
  props: {
    iconColor: {
      type: String,
      default: "#333333",
    },
  },
  setup() {},
};
</script>
<template>
  <svg
    width="61"
    height="60"
    viewBox="0 0 61 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.8713 45.0761C12.4145 45.0761 11.9576 44.6954 11.9576 44.2385V16.3705C11.9576 15.9137 12.3384 15.533 12.8713 15.533C13.4043 15.533 13.7851 15.9137 13.7851 16.3705V44.2385C13.7089 44.6954 13.3282 45.0761 12.8713 45.0761Z"
      :fill="iconColor"
    />
    <path
      d="M47.3638 45.0761C46.9069 45.0761 46.4501 44.6954 46.4501 44.2385V16.3705C46.4501 15.9137 46.8308 15.533 47.3638 15.533C47.8968 15.533 48.2775 15.9137 48.2775 16.3705V44.2385C48.2013 44.6954 47.8206 45.0761 47.3638 45.0761Z"
      :fill="iconColor"
    />
    <path
      d="M47.3637 30.4568H12.8713C12.4145 30.4568 11.9576 30.0761 11.9576 29.6193V16.2182C11.9576 15.7614 12.3384 15.3807 12.8713 15.3807H47.3637C47.8206 15.3807 48.2774 15.7614 48.2774 16.2182V29.5431C48.2013 30.0761 47.8206 30.4568 47.3637 30.4568ZM13.7089 28.7056H46.45V17.1319H13.7089V28.7056Z"
      :fill="iconColor"
    />
    <path
      d="M14.0896 45.0761H11.6531C11.1201 45.0761 10.7394 44.6954 10.7394 44.2386C10.7394 43.7817 11.1201 43.401 11.6531 43.401H14.0896C14.5465 43.401 15.0033 43.7817 15.0033 44.2386C14.9272 44.6954 14.5465 45.0761 14.0896 45.0761Z"
      :fill="iconColor"
    />
    <path
      d="M48.5819 45.0761H46.1454C45.6885 45.0761 45.2317 44.6954 45.2317 44.2386C45.2317 43.7817 45.6124 43.401 46.1454 43.401H48.5819C49.0388 43.401 49.4957 43.7817 49.4957 44.2386C49.4957 44.6954 49.1149 45.0761 48.5819 45.0761Z"
      :fill="iconColor"
    />
    <path
      d="M17.0592 26.6497C16.6024 26.6497 16.1455 26.269 16.1455 25.8122V20.1015C16.2216 19.5685 16.6024 19.1878 17.0592 19.1878H19.8765C20.3333 19.1878 20.7902 19.5685 20.7902 20.0254C20.7902 20.4822 20.4095 20.8629 19.8765 20.8629H17.9729V25.6599C17.9729 26.269 17.5922 26.6497 17.0592 26.6497Z"
      :fill="iconColor"
    />
    <path
      d="M22.6176 26.6498C22.1607 26.6498 21.7039 26.2691 21.7039 25.8122V20.1015C21.7039 19.6447 22.0846 19.264 22.6176 19.264C23.0744 19.264 23.5313 19.6447 23.5313 20.1015V25.8122C23.4551 26.2691 23.0744 26.6498 22.6176 26.6498Z"
      :fill="iconColor"
    />
    <path
      d="M31.983 26.6498C31.5262 26.6498 31.0693 26.2691 31.0693 25.8122V20.1015C31.0693 19.6447 31.45 19.264 31.983 19.264C32.4399 19.264 32.8967 19.6447 32.8967 20.1015V25.8122C32.8967 26.2691 32.516 26.6498 31.983 26.6498Z"
      :fill="iconColor"
    />
    <path
      d="M25.6632 26.6497C25.2064 26.6497 24.7495 26.269 24.7495 25.8122V20.1015C24.8257 19.5685 25.2064 19.1878 25.6632 19.1878C26.1201 19.1878 26.5769 19.5685 26.5769 20.0254V25.736C26.5769 26.269 26.1962 26.6497 25.6632 26.6497Z"
      :fill="iconColor"
    />
    <path
      d="M29.0896 26.6498C28.6328 26.6498 28.1759 26.2691 28.1759 25.8122V20.1015C28.1759 19.6447 28.5566 19.264 29.0896 19.264C29.6226 19.264 30.0033 19.6447 30.0033 20.1015V25.8122C29.9272 26.2691 29.5465 26.6498 29.0896 26.6498Z"
      :fill="iconColor"
    />
    <path
      d="M18.9628 24.137H17.0592C16.6024 24.137 16.1455 23.7563 16.1455 23.2995C16.1455 22.8426 16.5262 22.4619 17.0592 22.4619H18.9628C19.4196 22.4619 19.8765 22.8426 19.8765 23.2995C19.8003 23.6802 19.4196 24.137 18.9628 24.137Z"
      :fill="iconColor"
    />
    <path
      d="M43.5567 24.137H40.2064C39.7496 24.137 39.2927 23.7563 39.2927 23.2995C39.2927 22.8426 39.6734 22.4619 40.2064 22.4619H43.5567C44.0135 22.4619 44.4704 22.8426 44.4704 23.2995C44.4704 23.6802 44.0897 24.137 43.5567 24.137Z"
      :fill="iconColor"
    />
    <path
      d="M36.0948 26.6497H34.6481C34.1912 26.6497 33.7344 26.269 33.7344 25.8122C33.7344 25.3553 34.1151 24.9746 34.6481 24.9746H36.0948C36.3993 24.9746 36.6278 24.7462 36.6278 24.4416C36.6278 24.137 36.3993 23.9086 36.0948 23.9086C34.8004 23.9086 33.8105 22.9188 33.8105 21.6244C33.8105 20.3299 34.8004 19.3401 36.0948 19.3401H37.5415C37.9983 19.3401 38.4552 19.7208 38.4552 20.1777C38.4552 20.6345 38.0745 21.0152 37.5415 21.0152H36.0948C35.7902 21.0152 35.5618 21.2436 35.5618 21.5482C35.5618 21.8528 35.7902 22.0812 36.0948 22.0812C37.3892 22.0812 38.379 23.0711 38.379 24.3655C38.379 25.6599 37.3892 26.6497 36.0948 26.6497Z"
      :fill="iconColor"
    />
    <path
      d="M25.6632 26.6497C25.2064 26.6497 24.7495 26.269 24.7495 25.8122V20.1015C24.8257 19.5685 25.2064 19.1878 25.6632 19.1878C26.1201 19.1878 26.5769 19.5685 26.5769 20.0254V25.736C26.5769 26.269 26.1962 26.6497 25.6632 26.6497Z"
      :fill="iconColor"
    />
    <path
      d="M29.0896 26.6497C28.785 26.6497 28.4805 26.4974 28.3282 26.1929L24.9779 20.4822C24.7495 20.1015 24.9018 19.4924 25.2825 19.2639C25.6632 19.0355 26.2723 19.1878 26.5008 19.5685L29.851 25.2792C30.0795 25.6599 29.9272 26.269 29.5465 26.4974C29.3942 26.6497 29.2419 26.6497 29.0896 26.6497Z"
      :fill="iconColor"
    />
    <path
      d="M40.2064 26.6498C39.7496 26.6498 39.2927 26.2691 39.2927 25.8122V20.1015C39.2927 19.6447 39.6734 19.264 40.2064 19.264C40.6633 19.264 41.1201 19.6447 41.1201 20.1015V25.8122C41.1201 26.2691 40.6633 26.6498 40.2064 26.6498Z"
      :fill="iconColor"
    />
    <path
      d="M43.5566 26.6497C43.0998 26.6497 42.6429 26.269 42.6429 25.8122V20.1015C42.7191 19.5685 43.0998 19.1878 43.5566 19.1878C44.0135 19.1878 44.4704 19.5685 44.4704 20.0254V25.736C44.4704 26.269 44.0896 26.6497 43.5566 26.6497Z"
      :fill="iconColor"
    />
    <path
      d="M40.2064 26.6498C39.7496 26.6498 39.2927 26.2691 39.2927 25.8122V20.1015C39.2927 19.6447 39.6734 19.264 40.2064 19.264C40.6633 19.264 41.1201 19.6447 41.1201 20.1015V25.8122C41.1201 26.2691 40.6633 26.6498 40.2064 26.6498Z"
      :fill="iconColor"
    />
  </svg>
</template>
