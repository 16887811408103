<script>
export default {
  name: "icon-swim",
  props: {
    iconColor: {
      type: String,
      default: "#333333",
    },
  },
};
</script>
<template>
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M44.2386 40.5838H16.4467C14.9238 40.5838 13.7056 39.3655 13.7056 37.8427V25.4315C13.7056 23.9086 14.9238 22.6904 16.4467 22.6904H44.2386C45.7614 22.6904 47.0558 23.9086 47.0558 25.4315V37.7665C47.0558 39.2894 45.7614 40.5838 44.2386 40.5838ZM16.4467 24.4416C15.9137 24.4416 15.4568 24.8985 15.4568 25.4315V37.7665C15.4568 38.2995 15.9137 38.8325 16.4467 38.8325H44.2386C44.7716 38.8325 45.2284 38.3756 45.2284 37.7665V25.4315C45.2284 24.8985 44.7716 24.4416 44.2386 24.4416H16.4467Z"
      :fill="iconColor"
    />
    <path
      d="M42.1066 26.1929C41.6497 26.1929 41.1929 25.8122 41.1929 25.3554V18.198C41.1929 16.5229 42.4873 15.2285 44.1624 15.2285C44.6193 15.2285 45.0761 15.6092 45.0761 16.066C45.0761 16.5229 44.6954 16.9036 44.1624 16.9036C43.4771 16.9036 42.9441 17.4366 42.9441 18.1219V25.2792C42.9441 25.8122 42.5634 26.1929 42.1066 26.1929Z"
      :fill="iconColor"
    />
    <path
      d="M36.8528 26.1929C36.3959 26.1929 35.9391 25.8122 35.9391 25.3554V18.198C35.9391 16.599 37.2335 15.2285 38.9086 15.2285C39.3655 15.2285 39.8223 15.6092 39.8223 16.066C39.8223 16.5229 39.4416 16.9036 38.9086 16.9036C38.2233 16.9036 37.6904 17.4366 37.6904 18.1219V25.2792C37.6904 25.8122 37.3096 26.1929 36.8528 26.1929Z"
      :fill="iconColor"
    />
    <path
      d="M42.1066 21.0153H36.8528C36.3959 21.0153 35.9391 20.6346 35.9391 20.1777C35.9391 19.7209 36.3198 19.3401 36.8528 19.3401H42.1066C42.5634 19.3401 43.0203 19.7209 43.0203 20.1777C42.9442 20.6346 42.5634 21.0153 42.1066 21.0153Z"
      :fill="iconColor"
    />
    <path
      d="M17.7411 31.7513C17.2842 31.7513 16.8274 31.3706 16.8274 30.9137C16.8274 30.4569 17.2081 30.0762 17.7411 30.0762C18.5787 30.0762 19.2639 29.6954 19.3401 29.3147C19.4162 28.8579 19.9492 28.5533 20.4061 28.6295C20.8629 28.7056 21.1675 29.2386 21.0914 29.6954C20.7868 30.8376 19.4162 31.7513 17.7411 31.7513Z"
      :fill="iconColor"
    />
    <path
      d="M22.7665 31.7513C21.0914 31.7513 19.7208 30.8376 19.4163 29.6193C19.3401 29.1625 19.5685 28.7056 20.1015 28.5533C20.5584 28.4772 21.0152 28.7056 21.1675 29.2386C21.2437 29.5432 21.8528 30 22.7665 30C23.7564 30 24.3655 29.5432 24.4416 29.2386C24.5178 28.7818 25.0508 28.4772 25.5076 28.5533C25.9645 28.6295 26.269 29.1625 26.1929 29.6193C25.8883 30.8376 24.4416 31.7513 22.7665 31.7513Z"
      :fill="iconColor"
    />
    <path
      d="M27.8679 31.7513C26.1928 31.7513 24.8223 30.8376 24.5177 29.6193C24.4416 29.1625 24.67 28.7056 25.203 28.5533C25.6598 28.4772 26.1167 28.7056 26.269 29.2386C26.3451 29.6193 27.0304 30 27.8679 30C28.7055 30 29.3908 29.6193 29.4669 29.2386C29.5431 28.7818 30.0761 28.4772 30.5329 28.5533C30.9898 28.6295 31.2943 29.1625 31.2182 29.6193C30.9136 30.8376 29.5431 31.7513 27.8679 31.7513Z"
      :fill="iconColor"
    />
    <path
      d="M32.8933 31.7513C31.2182 31.7513 29.8477 30.8376 29.5431 29.6193C29.4669 29.1625 29.6954 28.7056 30.2284 28.5533C30.6852 28.4772 31.1421 28.7056 31.2944 29.2386C31.3705 29.5432 31.9796 30 32.9695 30C33.8832 30 34.4923 29.5432 34.5685 29.2386C34.6446 28.7818 35.1776 28.4772 35.6345 28.5533C36.0913 28.6295 36.3959 29.1625 36.3197 29.6193C35.939 30.8376 34.5685 31.7513 32.8933 31.7513Z"
      :fill="iconColor"
    />
    <path
      d="M37.9187 31.7513C36.2436 31.7513 34.873 30.8376 34.5685 29.6193C34.4923 29.1625 34.7208 28.7056 35.2538 28.5533C35.7106 28.4772 36.1675 28.7056 36.3197 29.2386C36.3959 29.5432 37.005 30 37.9187 30C38.9086 30 39.5177 29.5432 39.5939 29.2386C39.67 28.7818 40.203 28.4772 40.6599 28.5533C41.1167 28.6295 41.4213 29.1625 41.3451 29.6193C40.9644 30.8376 39.5939 31.7513 37.9187 31.7513Z"
      :fill="iconColor"
    />
    <path
      d="M42.9441 31.7513C41.269 31.7513 39.8984 30.8376 39.5939 29.6193C39.5177 29.1625 39.7462 28.7056 40.2791 28.5533C40.736 28.4772 41.1928 28.7056 41.3451 29.2386C41.4213 29.6193 42.1066 30 42.9441 30C43.401 30 43.8578 30.3807 43.8578 30.8376C43.8578 31.3706 43.4771 31.7513 42.9441 31.7513Z"
      :fill="iconColor"
    />
    <path
      d="M20.33 36.7005C19.8731 36.7005 19.4163 36.3198 19.4163 35.8629C19.4163 35.4061 19.797 35.0254 20.33 35.0254C21.1675 35.0254 21.8528 34.6447 21.9289 34.264C22.0051 33.8071 22.5381 33.5025 22.9949 33.5787C23.4518 33.6548 23.7564 34.1878 23.6802 34.6447C23.3756 35.8629 22.0051 36.7005 20.33 36.7005Z"
      :fill="iconColor"
    />
    <path
      d="M25.3554 36.7005C23.6802 36.7005 22.3097 35.7868 22.0051 34.5685C21.929 34.1117 22.1574 33.6548 22.6904 33.5026C23.1473 33.4264 23.6041 33.6548 23.7564 34.1878C23.8325 34.5685 24.5178 34.9493 25.3554 34.9493C26.3452 34.9493 26.9544 34.4924 27.0305 34.1878C27.1066 33.731 27.6396 33.4264 28.0965 33.5026C28.5533 33.5787 28.8579 34.1117 28.7818 34.5685C28.4011 35.7868 27.0305 36.7005 25.3554 36.7005Z"
      :fill="iconColor"
    />
    <path
      d="M30.3808 36.7005C28.7056 36.7005 27.3351 35.7868 27.0305 34.5685C26.9544 34.1117 27.1828 33.6548 27.7158 33.5026C28.1726 33.4264 28.6295 33.6548 28.7818 34.1878C28.8579 34.5685 29.5432 34.9493 30.3808 34.9493C31.2183 34.9493 31.9036 34.5685 31.9798 34.1878C32.0559 33.731 32.5889 33.4264 33.0457 33.5026C33.5026 33.5787 33.8072 34.1117 33.731 34.5685C33.4265 35.863 32.0559 36.7005 30.3808 36.7005Z"
      :fill="iconColor"
    />
    <path
      d="M35.4061 36.7005C33.731 36.7005 32.3604 35.7868 32.0559 34.5685C31.9797 34.1117 32.2843 33.6548 32.7411 33.5026C33.198 33.4264 33.6548 33.6548 33.8071 34.1878C33.8833 34.4924 34.4924 34.9493 35.4822 34.9493C36.3198 34.9493 37.0051 34.5685 37.0812 34.1878C37.1574 33.731 37.6904 33.4264 38.1472 33.5026C38.6041 33.5787 38.9086 34.1117 38.8325 34.5685C38.5279 35.7868 37.0812 36.7005 35.4061 36.7005Z"
      :fill="iconColor"
    />
    <path
      d="M40.5076 36.7005C38.8325 36.7005 37.4619 35.7868 37.1573 34.5685C37.0812 34.1117 37.3096 33.6548 37.8426 33.5026C38.2995 33.4264 38.7563 33.6548 38.9086 34.1878C38.9848 34.5685 39.67 34.9493 40.5076 34.9493C40.9644 34.9493 41.4213 35.33 41.4213 35.7868C41.3452 36.3198 40.9644 36.7005 40.5076 36.7005Z"
      :fill="iconColor"
    />
    <path
      d="M44.2386 44.3147H16.4467C14.9238 44.3147 13.7056 43.0965 13.7056 41.5736V37.7665C13.7056 37.3097 14.0863 36.929 14.6193 36.929C15.0761 36.929 15.533 37.3097 15.533 37.7665C15.533 38.2995 15.9898 38.8325 16.5228 38.8325H44.3147C44.8477 38.8325 45.3045 38.3757 45.3045 37.7665C45.3045 37.3097 45.6853 36.929 46.2183 36.929C46.6751 36.929 47.132 37.3097 47.132 37.7665V41.5736C47.0558 43.0965 45.7614 44.3147 44.2386 44.3147ZM15.4568 40.3554V41.5736C15.4568 42.1066 15.9137 42.5635 16.4467 42.5635H44.2386C44.7716 42.5635 45.2284 42.1066 45.2284 41.5736V40.3554C44.9238 40.5076 44.5431 40.5838 44.2386 40.5838H16.4467C16.1421 40.5838 15.7614 40.5076 15.4568 40.3554Z"
      :fill="iconColor"
    />
  </svg>
</template>
