<script>
import IconSearch from "@/components/Icons/IconSearch.vue";
import { useStore } from "vuex";
import { computed, ref } from "vue";
import { useRouter } from "vue-router";

export default {
  name: "search-input",
  components: { IconSearch },
  props: {
    searchstr: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const input = ref("");

    function searching() {
      store.commit("SET_SEARCH_STR", {
        searchStr: input.value,
      });
      router.push({ name: "Search", query: { search: input.value } });
      input.value = "";
    }

    return {
      input,
      inputLen: computed(() => input.value.length),
      searching,
      formatedSearch: computed(() =>
        props.searchstr === "" ? "Поиск" : props.searchstr
      ),
      searchClass: computed(() => (props.searchstr === "" ? false : true)),
    };
  },
};
</script>

<template>
  <form class="search_form" @submit.prevent="searching()">
    <input type="text" placeholder="Поиск" v-model="input" />
    <button
      class="btn btn-primary"
      @click="searching"
      :disabled="inputLen <= 3"
    >
      искать
    </button>
    <icon-search @click="searching" />
  </form>
</template>

<style lang="sass">
.search-layout
  & .search_form
    display: flex
    flex-direction: column
    gap: 16px
    margin-top: 25px
  & input
    padding: 16px
    border-radius: 10px
    border: 1px solid #d4d4d4
  & svg
    display: none
</style>
