<script>
export default {
  name: "icon-winter-sport",
  props: {
    iconColor: {
      type: String,
      default: "#333333",
    },
  },
  setup() {},
};
</script>
<template>
  <svg
    width="61"
    height="60"
    viewBox="0 0 61 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M45.616 35.4822H17.5195C17.0627 35.4822 16.6058 35.1015 16.6058 34.6446V33.2741C16.6058 30.533 18.3571 28.0964 20.9459 27.1827L28.3317 24.7462C30.3114 24.0609 31.9104 22.4619 32.4434 20.4061L32.9764 18.5025C33.0525 18.1218 33.4332 17.8934 33.814 17.8934H44.7784C45.2353 17.8934 45.616 18.2741 45.616 18.7309L46.3774 34.5685C46.3774 34.7969 46.3013 35.0253 46.149 35.1776C46.0728 35.4061 45.8444 35.4822 45.616 35.4822ZM18.4332 33.7309H44.7023L44.017 19.6446H34.4992L34.1947 20.8629C33.5094 23.4517 31.5297 25.5076 28.9409 26.4213L21.5551 28.8578C19.6515 29.5431 18.4332 31.2944 18.4332 33.2741V33.7309Z"
      :fill="iconColor"
    />
    <path
      d="M42.7987 39.3655H38.9916C38.0779 39.3655 37.1642 38.9848 36.4789 38.2995C36.1744 37.9949 35.7175 37.7665 35.2607 37.7665H34.6515C34.1947 37.7665 33.7378 37.9188 33.4332 38.2995C32.748 38.9848 31.9104 39.3655 30.9206 39.3655H20.3368C18.281 39.3655 16.6058 37.6903 16.6058 35.6345V34.5685C16.6058 34.1117 16.9865 33.731 17.5195 33.731H45.616C46.0728 33.731 46.5297 34.1117 46.5297 34.5685V35.6345C46.4535 37.6903 44.7784 39.3655 42.7987 39.3655ZM34.6515 36.0914H35.2607C36.1744 36.0914 37.0881 36.4721 37.7733 37.1574C38.0779 37.4619 38.5348 37.6903 38.9916 37.6903H42.7987C43.8647 37.6903 44.7784 36.8528 44.7784 35.7107V35.5584H18.4332V35.7107C18.4332 36.7766 19.2708 37.6903 20.4129 37.6903H30.9206C31.3774 37.6903 31.8343 37.5381 32.1388 37.1574C32.8241 36.4721 33.7378 36.0914 34.6515 36.0914Z"
      :fill="iconColor"
    />
    <path
      d="M26.8088 29.0863C26.5043 29.0863 26.1997 28.934 26.0474 28.5533L25.2099 26.8782C24.9814 26.4213 25.2099 25.8883 25.6667 25.736C26.1236 25.5076 26.6566 25.736 26.8088 26.1929L27.6464 27.868C27.8748 28.3249 27.6464 28.8579 27.1895 29.0101C27.0373 29.0101 26.9611 29.0863 26.8088 29.0863Z"
      :fill="iconColor"
    />
    <path
      d="M30.3875 28.0964C30.083 28.0964 29.7784 27.9441 29.6261 27.6396L28.484 25.736C28.2556 25.3553 28.3317 24.7462 28.7886 24.5177C29.1693 24.2893 29.7784 24.3655 30.0068 24.8223L31.149 26.7259C31.3774 27.1066 31.3012 27.7157 30.8444 27.9441C30.6921 28.0964 30.5398 28.0964 30.3875 28.0964Z"
      :fill="iconColor"
    />
    <path
      d="M40.0576 35.4822C39.9815 35.4822 39.9053 35.4822 39.8292 35.4822C39.3723 35.4061 39.0677 34.8731 39.22 34.4162C39.8292 31.8274 42.0373 29.3908 44.9307 27.868C45.3875 27.6396 45.9205 27.7919 46.0728 28.2487C46.3013 28.7056 46.149 29.2386 45.6921 29.3908C43.2556 30.6091 41.352 32.665 40.8952 34.7208C40.819 35.2538 40.4383 35.4822 40.0576 35.4822Z"
      :fill="iconColor"
    />
    <path
      d="M44.2454 19.6447H34.8038C33.5855 19.6447 32.5195 18.6548 32.5195 17.3604V16.1421C32.5195 14.9238 33.5094 13.8578 34.8038 13.8578H44.2454C45.4637 13.8578 46.5297 14.8477 46.5297 16.1421V17.4365C46.4535 18.6548 45.4637 19.6447 44.2454 19.6447ZM34.8038 15.6091C34.4992 15.6091 34.2708 15.8375 34.2708 16.1421V17.4365C34.2708 17.7411 34.4992 17.9695 34.8038 17.9695H44.2454C44.55 17.9695 44.7784 17.7411 44.7784 17.4365V16.1421C44.7784 15.8375 44.55 15.6091 44.2454 15.6091H34.8038Z"
      :fill="iconColor"
    />
    <path
      d="M19.8799 45.9898C18.4332 45.9898 16.4535 45.6852 14.7784 44.467C13.7885 43.7055 13.5601 42.1066 14.3215 41.0406C14.8545 40.203 16.0728 39.67 17.7479 40.6599C18.5855 41.1928 19.7276 41.269 20.7175 41.269H22.3164C22.6971 41.269 23.0779 40.9644 23.0779 40.5076V38.5279C23.0779 38.071 23.4586 37.6903 23.9916 37.6903C24.4484 37.6903 24.9053 38.071 24.9053 38.5279V40.5076C24.9053 41.8781 23.7631 43.0203 22.3926 43.0203H20.6413C19.423 43.0203 17.9763 42.9441 16.7581 42.1827C16.3774 41.9543 15.9205 41.802 15.6921 42.1066C15.4636 42.4111 15.5398 42.9441 15.7682 43.1725C17.0626 44.1624 18.6616 44.3908 19.8037 44.3908H45.1591C45.3114 44.2385 45.5398 43.8578 45.5398 43.4771C45.5398 43.2487 45.5398 43.1725 45.5398 43.1725C45.5398 43.1725 45.3875 43.0964 45.2352 43.0964H42.7987C41.4281 43.0964 40.286 41.9543 40.286 40.5837V38.604C40.286 38.1472 40.6667 37.7665 41.1997 37.7665C41.6565 37.7665 42.1134 38.1472 42.1134 38.604V40.5837C42.1134 40.9644 42.418 41.3451 42.8748 41.3451H45.3875C46.6819 41.3451 47.5195 42.1066 47.5195 43.3248C47.5195 44.467 46.6819 46.1421 45.5398 46.1421H19.8799V45.9898Z"
      :fill="iconColor"
    />
    <path
      d="M35.489 43.0203H29.8545C28.1032 43.0203 26.6565 41.7259 26.6565 40.1269V38.5279C26.6565 38.0711 27.0372 37.6904 27.5702 37.6904C28.0271 37.6904 28.4839 38.0711 28.4839 38.5279V40.1269C28.4839 40.7361 29.1692 41.269 29.9306 41.269H35.5651C36.3265 41.269 37.0118 40.7361 37.0118 40.1269V38.3756C37.0118 37.9188 37.3925 37.5381 37.9255 37.5381C38.3824 37.5381 38.8392 37.9188 38.8392 38.3756V40.1269C38.687 41.6498 37.2403 43.0203 35.489 43.0203Z"
      :fill="iconColor"
    />
  </svg>
</template>
