<script>
export default {
  name: "icon-foto",
  props: {
    iconColor: {
      type: String,
      default: "#333333",
    },
  },
  setup() {},
};
</script>
<template>
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_246:1937)">
      <path
        d="M30.2694 24.5872C27.9033 24.5872 25.9851 26.5052 25.9851 28.8714C25.9851 31.2375 27.9032 33.1557 30.2694 33.1557C32.6354 33.1557 34.5536 31.2376 34.5536 28.8714C34.5536 26.5052 32.6355 24.5872 30.2694 24.5872ZM30.2694 31.1154C29.0299 31.1154 28.0252 30.1107 28.0252 28.8713C28.0252 27.6319 29.0299 26.6272 30.2694 26.6272C31.5088 26.6272 32.5135 27.6319 32.5135 28.8713C32.5135 30.1107 31.5088 31.1154 30.2694 31.1154Z"
        :fill="iconColor"
      />
      <path
        d="M47.3552 14.7947L19.5078 11.6326C18.4271 11.4789 17.332 11.7944 16.4987 12.4997C15.6655 13.1457 15.1314 14.1034 15.0197 15.1518L14.5097 19.334H12.9285C10.6844 19.334 9.00126 21.323 9.00126 23.5672V44.4272C8.94473 46.5672 10.6337 48.3479 12.7738 48.4044C12.8253 48.4058 12.877 48.4061 12.9285 48.4054H40.9289C43.173 48.4054 45.2131 46.6713 45.2131 44.4272V43.6112C45.9089 43.4767 46.5689 43.1988 47.1512 42.7951C47.9776 42.0994 48.5067 41.1141 48.6303 40.041L50.9764 19.334C51.2156 17.0847 49.6014 15.0613 47.3552 14.7947ZM43.173 44.4272C43.173 45.5493 42.0509 46.3653 40.9289 46.3653H12.9285C11.9149 46.3951 11.0691 45.5975 11.0393 44.5839C11.0378 44.5317 11.0384 44.4794 11.0414 44.4272V40.653L18.9468 34.8387C19.8965 34.1096 21.2341 34.1744 22.109 34.9917L27.6683 39.888C28.5125 40.5968 29.5752 40.993 30.6775 41.01C31.5393 41.0206 32.3869 40.7911 33.1256 40.347L43.1731 34.5327V44.4272H43.173ZM43.173 32.1356L32.0544 38.6129C31.0997 39.1785 29.8912 39.0761 29.0452 38.3579L23.435 33.4106C21.827 32.029 19.4769 31.9442 17.7737 33.2066L11.0414 38.1028V23.5672C11.0414 22.4451 11.8064 21.3741 12.9285 21.3741H40.9289C42.1277 21.4238 43.0958 22.3699 43.173 23.5672V32.1356ZM48.9383 19.0586C48.9376 19.0653 48.937 19.0722 48.9362 19.0789L46.5391 39.7859C46.5432 40.3229 46.2984 40.8314 45.8761 41.163C45.672 41.367 45.213 41.469 45.213 41.5711V23.5672C45.1325 21.2437 43.2531 19.3866 40.9288 19.334H16.5497L17.0087 15.3558C17.1083 14.8406 17.3776 14.3738 17.7738 14.0297C18.2211 13.7204 18.7619 13.5762 19.3039 13.6216L47.1003 16.8348C48.2218 16.9413 49.0448 17.9369 48.9383 19.0586Z"
        :fill="iconColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_246:1937">
        <rect width="42" height="42" fill="white" transform="translate(9 9)" />
      </clipPath>
    </defs>
  </svg>
</template>
