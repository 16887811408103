<script>
import { ref } from "vue";
import IconNews from "@/components/Icons/IconNews.vue";
// import IconSearch from "@/components/Icons/IconSearch.vue";

import IconMoreLogo from "@/assets/logo-blue.svg";
import { useStore } from "vuex";
import IconSwim from "./Icons/IconSwim.vue";
import { useFetch } from "@vueuse/core";
import IconFootball from "./Icons/IconFootball.vue";
import IconTeamGames from "./Icons/IconTeamGames.vue";
import IconFitghing from "./Icons/IconFitghing.vue";
import IconRunning from "./Icons/IconRunning.vue";
import IconBulletShoot from "./Icons/IconBulletShoot.vue";
import IconBow from "./Icons/IconBow.vue";
import IconSekcii from "./Icons/IconSekcii.vue";
import IconTournaments from "./Icons/IconTournaments.vue";
import IconTraining from "./Icons/IconTraining.vue";
import IconHorseSport from "./Icons/IconHorseSport.vue";
import IconWinterSport from "./Icons/IconWinterSport.vue";
import IconSaun from "./Icons/IconSaun.vue";
import IconHotel from "./Icons/IconHotel.vue";
import IconDssStudio from "./Icons/IconDssStudio.vue";
import IconEvent from "./Icons/IconEvent.vue";
import IconHeadquartes from "./Icons/IconHeadquartes.vue";
import IconFoto from "./Icons/IconFoto.vue";
import IconDocs from "./Icons/IconDocs.vue";
import IconZakupki from "./Icons/IconZakupki.vue";
import IconJobs from "./Icons/IconJobs.vue";
import IconPartners from "./Icons/IconPartners.vue";
import IconLinks from "./Icons/IconLinks.vue";
import IconReception from "./Icons/IconReception.vue";
import IconContact from "./Icons/IconContact.vue";
import SearchInput from "./SearchInput.vue";
import MobileMenu from "./MobileMenu.vue";
export default {
  components: {
    IconNews,
    // IconSearch,
    IconSwim,
    IconFootball,
    IconTeamGames,
    IconFitghing,
    IconRunning,
    IconBulletShoot,
    IconBow,
    IconSekcii,
    IconTournaments,
    IconTraining,
    IconHorseSport,
    IconWinterSport,
    IconSaun,
    IconHotel,
    IconDssStudio,
    IconEvent,
    IconHeadquartes,
    IconFoto,
    IconDocs,
    IconZakupki,
    IconJobs,
    IconPartners,
    IconLinks,
    IconReception,
    IconContact,
    SearchInput,
    MobileMenu,
  },
  setup() {
    const dropDown = ref(false);
    const dropDownVacation = ref(false);
    const dropDownManagement = ref(false);

    function fetchSportMenu() {
      let url = "https://admin.dss-sport.ru/wp-json/wp/v2/pages/2";
      const { data } = useFetch(url).get().json();
      return data;
    }
    function fetchVacationMenu() {
      let url = "https://admin.dss-sport.ru/wp-json/wp/v2/pages/299";
      const { data } = useFetch(url).get().json();
      return data;
    }
    function fetchManagementMenu() {
      let url = "https://admin.dss-sport.ru/wp-json/wp/v2/pages/335";
      const { data } = useFetch(url).get().json();
      return data;
    }

    const sportMenuItems = fetchSportMenu();
    const vacationMenuItems = fetchVacationMenu();
    const managementMenuItems = fetchManagementMenu();
    const store = useStore();

    return {
      dropDown,
      dropDownVacation,
      dropDownManagement,
      IconMoreLogo,
      sportMenuItems,
      vacationMenuItems,
      managementMenuItems,
      toggleNews: () => store.commit("TOGGLE_NEWS_SIDER_BAR"),
    };
  },
};
</script>

<template>
  <header class="header">
    <div class="header__wrapper">
      <div class="header__left">
        <div class="logo">
          <router-link to="/">
            <img src="../assets/dss-logo.png" alt="" />
          </router-link>
        </div>
        <div class="main-menu">
          <ul class="main-menu__list">
            <li
              class="main-menu__item"
              @mouseover="dropDown = true"
              @mouseleave="dropDown = false"
            >
              <router-link
                :to="{
                  name: 'Category',
                  params: { slug: 'sport' },
                }"
                >СПОРТ</router-link
              >
              <div
                class="main-menu__dropdown"
                :class="{ isShow: dropDown }"
                @mouseleave="dropDown = false"
              >
                <div class="dropdown" @mouseover="dropDown = true">
                  <div class="dropdown__title">
                    <h3>
                      <router-link
                        :to="{
                          name: 'Category',
                          params: { slug: 'sport' },
                        }"
                        >СПОРТ</router-link
                      >
                    </h3>
                  </div>
                  <div class="dropdown__links">
                    <template
                      v-for="item in sportMenuItems?.childrens"
                      :key="item.id"
                    >
                      <router-link
                        class="dropdown__link"
                        :to="{
                          name: 'Page',
                          params: { type: 'sport', slug: item.id },
                        }"
                        @click="dropDown = false"
                      >
                        <icon-swim v-if="item.id === 235" />
                        <icon-football v-else-if="item.id === 246" />
                        <icon-team-games v-else-if="item.id === 249" />
                        <icon-fitghing v-else-if="item.id === 251" />
                        <icon-running v-else-if="item.id === 253" />
                        <icon-bullet-shoot v-else-if="item.id === 255" />
                        <icon-bow v-else-if="item.id === 257" />
                        <icon-sekcii v-else-if="item.id === 259" />
                        <icon-winter-sport v-else-if="item.id === 261" />
                        <icon-tournaments v-else-if="item.id === 263" />
                        <icon-training v-else-if="item.id === 292" />
                        <icon-horse-sport v-else-if="item.id === 294" />
                        <div class="link__group">
                          <div class="link__title">{{ item?.title }}</div>
                          <div
                            v-if="item?.acf?.description"
                            class="link__subtitle"
                          >
                            {{ item?.acf?.description }}
                          </div>
                        </div>
                      </router-link>
                    </template>
                  </div>
                </div>
              </div>
            </li>
            <li
              class="main-menu__item"
              @mouseover="dropDownVacation = true"
              @mouseleave="dropDownVacation = false"
            >
              <router-link
                :to="{
                  name: 'Category',
                  params: { slug: 'vacation' },
                }"
                >ОТДЫХ</router-link
              >
              <div
                class="main-menu__dropdown"
                :class="{ isShow: dropDownVacation }"
                @mouseleave="dropDownVacation = false"
              >
                <div class="dropdown" @mouseover="dropDownVacation = true">
                  <div class="dropdown__title">
                    <h3>
                      <router-link
                        :to="{
                          name: 'Category',
                          params: { slug: 'vacation' },
                        }"
                        >ОТДЫХ</router-link
                      >
                    </h3>
                  </div>
                  <div class="dropdown__links">
                    <template
                      v-for="item in vacationMenuItems?.childrens"
                      :key="item.id"
                    >
                      <router-link
                        class="dropdown__link"
                        :to="{
                          name: 'Page',
                          params: { type: 'vacation', slug: item.id },
                        }"
                        @click="dropDownVacation = false"
                      >
                        <icon-saun v-if="item.id === 327" />
                        <icon-football v-else-if="item.id === 329" />
                        <icon-hotel v-else-if="item.id === 331" />
                        <icon-dss-studio v-else-if="item.id === 357" />
                        <icon-event v-else-if="item.id === 365" />
                        <div class="link__group">
                          <div class="link__title">{{ item?.title }}</div>
                          <div
                            v-if="item?.acf?.description"
                            class="link__subtitle"
                          >
                            {{ item?.acf?.description }}
                          </div>
                        </div>
                      </router-link>
                    </template>
                  </div>
                </div>
              </div>
            </li>
            <li
              class="main-menu__item"
              @mouseover="dropDownManagement = true"
              @mouseleave="dropDownManagement = false"
            >
              <router-link
                :to="{
                  name: 'Category',
                  params: { slug: 'management' },
                }"
                >ДИРЕКЦИЯ</router-link
              >
              <div
                class="main-menu__dropdown"
                :class="{ isShow: dropDownManagement }"
                @mouseleave="dropDownManagement = false"
              >
                <div class="dropdown" @mouseover="dropDownManagement = true">
                  <div class="dropdown__title">
                    <h3>
                      <router-link
                        :to="{
                          name: 'Category',
                          params: { slug: 'management' },
                        }"
                        >ДИРЕКЦИЯ</router-link
                      >
                    </h3>
                  </div>
                  <div class="dropdown__links">
                    <template
                      v-for="item in managementMenuItems?.childrens"
                      :key="item.id"
                    >
                      <router-link
                        class="dropdown__link"
                        v-if="item.id != 353"
                        :to="{
                          name: 'Page',
                          params: { type: 'management', slug: item.id },
                        }"
                        @click="dropDownManagement = false"
                      >
                        <icon-headquartes v-if="item.id === 337" />
                        <icon-event v-else-if="item.id === 339" />
                        <icon-foto v-else-if="item.id === 341" />
                        <icon-docs v-else-if="item.id === 343" />
                        <icon-zakupki v-else-if="item.id === 345" />
                        <icon-jobs v-else-if="item.id === 347" />
                        <icon-partners v-else-if="item.id === 349" />
                        <icon-links v-else-if="item.id === 351" />
                        <icon-contact v-else-if="item.id === 355" />
                        <div class="link__group">
                          <div class="link__title">{{ item?.title }}</div>
                          <div
                            v-if="item?.acf?.description"
                            class="link__subtitle"
                          >
                            {{ item?.acf?.description }}
                          </div>
                        </div>
                      </router-link>
                      <router-link
                        class="dropdown__link"
                        v-if="item.id === 353"
                        :to="item?.acf?.linked_link"
                        :key="item.id"
                        @click="dropDownManagement = false"
                      >
                        <icon-reception />
                        <div class="link__group">
                          <div class="link__title">{{ item?.title }}</div>
                          <div
                            v-if="item?.acf?.description"
                            class="link__subtitle"
                          >
                            {{ item?.acf?.description }}
                          </div>
                        </div>
                      </router-link>
                    </template>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="header__right">
        <div class="phone">
          <span>+7 (3012) 21-10-41</span>
        </div>
        <search-input />
        <div class="news" @click="toggleNews">
          <span>НОВОСТИ</span>
          <icon-news />
        </div>
        <mobile-menu />
      </div>
    </div>
  </header>
</template>

<style lang="sass">
@use "sass:math"
@import "../styles/scss/_mixins.scss"

.isShow
  opacity: 1 !important
  visibility: visible !important

.header
  &__wrapper
    position: relative
    padding: var(--default)
    @include d-flex(row, space-between, center, null, null, null)
    @media only screen and (max-width: $sizes-xl)
      gap: $default-padding*1.5
    @media only screen and (max-width: $sizes-lg)
      gap: $default-padding*2.75
    @media only screen and (max-width: $sizes-xs)
      gap: $default-padding*.85
  &__left
    @include d-flex(row, flex-start, center, null, null, null)
    flex: 1
    & .logo
      display: inline-block
      & img
        width: 100%
        max-width: 180px
        @media only screen and (max-width: $sizes-md)
          height: 100%
          object-fit: cover
    & .main-menu
      @media only screen and (max-width: $sizes-lg - $default-padding*2)
        display: none
      &__list
        list-style-type: none
        @include d-flex(row, flex-start, flex-start, null, null, $default-padding*2.75)
        padding: 0
        margin-left: $default-padding*5.25
        @media only screen and (max-width: $sizes-xl)
          margin-left: $default-padding*2.75
      &__item
        height: 60px
        @include d-flex(row, null, center, null, null, null)
        font-weight: 500
        & .router-link-active
          color: $primary-color
      &__dropdown
        position: absolute
        top: 120px
        left: 0
        right: 0
        visibility: hidden
        opacity: 0
        transition: all .3s ease .15s
        transition-delay: .15s
        background: $base-color
        padding: $default-padding*2.8 $default-padding*3.75
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05)
        z-index: 10
  &__right
    @include d-flex(row, space-between, center, null, null, null)
    flex:1
    @media only screen and (max-width: $sizes-lg - $default-padding*2)
      flex: 2
      justify-content: flex-end
      gap: $default-padding*1.5
      @media only screen and (max-width: $sizes-sm)
        gap: $default-padding*1.1
    & .phone
      color: $m-link-color
      font-size: $base-font-size*1.5
      font-weight: 600
      @media only screen and (max-width: $sizes-lg - $default-padding*2)
        font-size: $base-font-size*1.15
      @media only screen and (max-width: $sizes-sm)
        font-size: $base-font-size*.75
      @media (max-width: $sizes-sm - $default-padding*6)
        display: none
    & .search_form
      @include d-flex(row, null, center, null, null, $default-padding*.35)
      & input
        border: none
        border-bottom: 2px solid $sub-text-color
        transition: .3s all
        padding: math.div($default-padding, 4)
        color: $primary-color
        &:focus
          border-color: $primary-color
        @media only screen and (max-width: $sizes-md)
          display: none
      & button
        display: none
        @media only screen and (max-width: $sizes-xl)
          display: none
    & .news
      @include d-flex(row, null, center, null, null, $default-padding*.5)
      cursor: pointer
      // @media (min-width: $sizes-exl + $default-padding*8.75)
      //   display: none
      & span
        font-weight: 500
        @media only screen and (max-width: $sizes-lg)
          display: none
    & .mobile-menu
      display: none
      @media only screen and (max-width: $sizes-lg - $default-padding*2)
        display: inline-flex

.dropdown
  @include d-flex(row, flex-start, null, null, null, $default-padding*5)
  &__title
    font-weight: 800
    font-size: $base-font-size*2
    & h3
      margin: 0
  &__links
    width: 100%
    display: grid
    grid-template-columns: 1fr 1fr 1fr
    grid-gap: $default-padding*2
    @media only screen and (min-width: $sizes-exl)
      grid-gap: 15px
    @media only screen and (max-width: $sizes-lg - $default-padding*2)
      grid-template-columns: 1fr 1fr
      grid-gap: $default-padding*.8 $default-padding*1.2
  &__link
    @include d-flex(row, flex-start, center, null, null, $default-padding*2)
    display: inline-flex
    padding-right: $default-padding*2
    transition: .3s background
    width: -webkit-fill-available
    @media only screen and (min-width: $sizes-max - 320px)
      max-width: 80%
    &:hover
      background: #eaf7fe
      & svg path
        transition: .3s fill
        fill: $primary-color
    &.router-link-active.router-link-exact-active
      background: #eaf7fe
      & svg path
        transition: .3s fill
        fill: $primary-color
    & .link__group
      font-size: $default-padding*.8
      & .link__title
        font-weight: 700
        margin-bottom: $default-padding*.3
      & .link__subtitle
        font-weight: normal
</style>
