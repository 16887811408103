<script>
import { computed } from "vue";
import { useStore } from "vuex";
import SearchInput from "../components/SearchInput.vue";
export default {
  name: "AppLayoutSearch",
  components: { SearchInput },
  setup() {
    const store = useStore();
    const search = computed(() => store.getters.getSearchStr);

    return { search };
  },
};
</script>

<template>
  <div class="layout search-layout">
    <div class="sidebar">
      <h3 class="sidebar__title">поиск</h3>
      <div>
        <search-input :searchstr="search" />
      </div>
    </div>
    <div class="search-block">
      <slot />
    </div>
  </div>
</template>

<style lang="sass" scoped>
@import "../styles/scss/_mixins.scss"

.search-layout
    width: 100%
    display: grid
    grid-template-columns: 340px 1fr
    gap: $default-padding*1.2
    @media only screen and (max-width: $sizes-md)
      display: flex
      flex-direction: column
    & .sidebar
      padding: $default-padding*1.9
      background: #f1f1f1
      &__title
        font-size: $base-font-size*2
        color: $primary-color
        font-weight: 800
        text-transform: uppercase
        margin: 0
      &__menu-list
        list-style: none
        padding: 0
        margin-top: $default-padding*1.5
      &__menu-link
        @include d-flex(column, flex-start, flex-start, null, null, 5px)
        margin-bottom: $default-padding*1.2
        position: relative
        &.router-link-active
          color: $primary-color
          &::before
            position: absolute
            top: 0
            left: -($default-padding*1.9)
            height: 100%
            content: ''
            width: 6px
            background: $primary-color
            border-radius: 0px 3px 3px 0px
          & .sidebar__menu-link--title
            font-weight: 800
        &--title
          font-weight: 700
          font-size: $base-font-size*.8
        &--subtitle
          font-size: $base-font-size*.8
</style>
