<template>
  <div class="layout category-layout">
    <div>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "AppLayoutCategory",
};
</script>

<style lang="sass"></style>
