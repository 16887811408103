<script>
export default {
  name: "icon-fighting",
  props: {
    iconColor: {
      type: String,
      default: "#333333",
    },
  },
  setup() {},
};
</script>
<template>
  <svg
    width="61"
    height="60"
    viewBox="0 0 61 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M40.511 41.1167H20.1049C19.8764 41.1167 19.7242 41.0406 19.5719 40.8883C17.3638 39.0609 16.0693 36.3198 16.0693 33.4264V19.1878C16.0693 15.3046 19.1912 12.1827 23.0744 12.1827H37.5414C41.4247 12.1827 44.5465 15.3046 44.5465 19.1878V33.4264C44.5465 36.3198 43.2521 38.9848 41.044 40.8883C40.9678 41.0406 40.7394 41.1167 40.511 41.1167ZM20.4094 39.3655H40.2064C41.8815 37.8426 42.7952 35.7107 42.7952 33.5025V19.1878C42.7952 16.2944 40.4348 13.934 37.5414 13.934H23.0744C20.181 13.934 17.8206 16.2944 17.8206 19.1878V33.4264C17.8206 35.7107 18.7343 37.8426 20.4094 39.3655Z"
      :fill="iconColor"
    />
    <path
      d="M38.7597 47.9695H21.8561C20.4855 47.9695 19.4196 46.9035 19.4196 45.533V40.203C19.4196 39.7462 19.8003 39.3655 20.3333 39.3655H40.3586C40.8155 39.3655 41.2723 39.7462 41.2723 40.203V45.533C41.1962 46.9035 40.1302 47.9695 38.7597 47.9695ZM21.1708 41.1167V45.6091C21.1708 45.9898 21.4754 46.2944 21.8561 46.2944H38.7597C39.1404 46.2944 39.4449 45.9898 39.4449 45.6091V41.1167H21.1708Z"
      :fill="iconColor"
    />
    <path
      d="M28.7851 34.1117C28.6328 34.1117 28.4805 34.1117 28.4044 34.0355C26.6531 33.1218 24.5973 31.7513 24.5973 29.0101V23.9086C24.5973 22.9188 24.2927 22.1573 23.7597 21.6244C23.3028 21.1675 22.5414 20.9391 21.78 20.9391C19.8764 20.9391 17.8967 22.3858 17.8967 25.0508V32.5888C17.8967 33.0457 17.516 33.4264 16.983 33.4264C16.45 33.4264 16.0693 33.1218 16.0693 32.5888V25.0508C16.0693 21.3959 18.9627 19.1878 21.7039 19.1878C22.9221 19.1878 24.0643 19.6447 24.9018 20.4061C25.8155 21.2436 26.2724 22.4619 26.2724 23.9848V29.0863C26.2724 30.4568 27.1099 31.4467 29.0896 32.5127C29.5465 32.7411 29.6988 33.2741 29.4703 33.6548C29.3942 33.9594 29.0896 34.1117 28.7851 34.1117Z"
      :fill="iconColor"
    />
    <path
      d="M34.8764 25.0508H25.4348C24.978 25.0508 24.5211 24.6701 24.5211 24.2132C24.5211 23.7564 24.9018 23.3757 25.4348 23.3757H34.8764C36.3231 23.3757 37.6176 22.5381 38.1506 21.2437C38.3028 20.7868 38.8358 20.5584 39.2927 20.7868C39.7495 20.9391 39.978 21.4721 39.7495 21.9289C38.912 23.7564 37.0084 25.0508 34.8764 25.0508Z"
      :fill="iconColor"
    />
    <path
      d="M40.3587 44.8477H37.3892C36.9323 44.8477 36.4755 44.467 36.4755 44.0102C36.4755 43.5533 36.8562 43.1726 37.3892 43.1726H40.2826C40.7394 43.1726 41.1963 43.5533 41.1963 44.0102C41.1963 44.467 40.8156 44.8477 40.3587 44.8477Z"
      :fill="iconColor"
    />
  </svg>
</template>
