<script>
export default {
  name: "icon-team-games",
  props: {
    iconColor: {
      type: String,
      default: "#333333",
    },
  },
  setup() {},
};
</script>
<template>
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M36.9289 32.8933H23.6802C22.3096 32.8933 21.1675 31.7512 21.1675 30.3806C21.1675 29.0101 22.3096 27.8679 23.6802 27.8679H36.9289C38.2995 27.8679 39.4416 29.0101 39.4416 30.3806C39.4416 31.8273 38.2995 32.8933 36.9289 32.8933ZM23.6802 29.6953C23.2995 29.6953 22.9187 29.9999 22.9187 30.4568C22.9187 30.8375 23.2233 31.2182 23.6802 31.2182H36.9289C37.3096 31.2182 37.6903 30.9136 37.6903 30.4568C37.6903 30.076 37.3858 29.6953 36.9289 29.6953H23.6802Z"
      :fill="iconColor"
    />
    <path
      d="M34.4162 45.3807H26.1167C25.6599 45.3807 25.3553 45.0761 25.2792 44.6954L23.071 32.4365C22.9949 32.2081 23.071 31.9035 23.2995 31.7512C23.4517 31.5228 23.6802 31.4467 23.9847 31.4467H36.6243C36.8528 31.4467 37.1573 31.5989 37.3096 31.7512C37.4619 31.9796 37.538 32.2081 37.538 32.4365L35.3299 44.6954C35.1776 45.0761 34.8731 45.3807 34.4162 45.3807ZM26.8781 43.6294H33.7309L35.6345 33.1218H24.9746L26.8781 43.6294Z"
      :fill="iconColor"
    />
    <path
      d="M31.599 45.3806H28.934C28.4771 45.3806 28.0964 44.9999 28.0964 44.5431L27.4111 32.3603C27.4111 32.1319 27.4873 31.9035 27.6396 31.6751C27.7918 31.5228 28.0203 31.3705 28.2487 31.3705H32.2842C32.5127 31.3705 32.7411 31.4466 32.8934 31.6751C33.0457 31.8273 33.1218 32.0558 33.1218 32.3603L32.4365 44.6192C32.4365 44.9999 32.0558 45.3806 31.599 45.3806ZM29.7715 43.6294H30.7614L31.3705 33.1218H29.2385L29.7715 43.6294Z"
      :fill="iconColor"
    />
    <path
      d="M35.8629 37.4619H24.6701C24.2132 37.4619 23.7563 37.0812 23.7563 36.6243C23.7563 36.1675 24.1371 35.7867 24.6701 35.7867H35.7868C36.2437 35.7867 36.7005 36.1675 36.7005 36.6243C36.7005 37.005 36.3198 37.4619 35.8629 37.4619Z"
      :fill="iconColor"
    />
    <path
      d="M35.1776 41.1167H25.3552C24.8984 41.1167 24.4415 40.7359 24.4415 40.2791C24.4415 39.8222 24.8222 39.4415 25.3552 39.4415H35.1776C35.6344 39.4415 36.0913 39.8222 36.0913 40.2791C36.0913 40.7359 35.6344 41.1167 35.1776 41.1167Z"
      :fill="iconColor"
    />
    <path
      d="M42.1066 36.7766H38.8325C38.3757 36.7766 37.9188 36.3959 37.9188 35.9391C37.9188 35.4822 38.2995 35.1015 38.8325 35.1015H41.2691V22.0051H19.3401V35.0254H21.7767C22.2335 35.0254 22.6904 35.4061 22.6904 35.8629C22.6904 36.3198 22.3097 36.7005 21.7767 36.7005H18.5026C18.0457 36.7005 17.5889 36.3198 17.5889 35.8629V21.0913C17.5889 20.6345 17.9696 20.2538 18.5026 20.2538H42.1066C42.5635 20.2538 43.0203 20.6345 43.0203 21.0913V35.8629C42.9442 36.3959 42.5635 36.7766 42.1066 36.7766Z"
      :fill="iconColor"
    />
    <path
      d="M25.4315 42.1827C25.3553 42.1827 25.3553 42.1827 25.2792 42.1827L11.4213 40.0507C10.9645 39.9746 10.6599 39.5938 10.6599 39.2131V17.1319C10.6599 16.7512 10.8883 16.4466 11.1929 16.2943C22.7665 11.4213 37.7665 11.4213 49.3401 16.2943C49.6447 16.4466 49.8731 16.7512 49.8731 17.1319V39.2131C49.8731 39.67 49.5685 40.0507 49.1117 40.0507L35.1777 42.1827C34.7208 42.2588 34.264 41.9542 34.1878 41.4212C34.1117 40.9644 34.4163 40.5075 34.9492 40.4314L48.1218 38.4517V17.6649C37.2335 13.2487 23.2995 13.2487 12.4112 17.6649V38.4517L25.5838 40.4314C26.0406 40.5075 26.4213 40.9644 26.3452 41.4212C26.269 41.8781 25.8883 42.1827 25.4315 42.1827Z"
      :fill="iconColor"
    />
    <path
      d="M28.8579 46.9796C28.4011 46.9796 27.9442 46.5988 27.9442 46.142V44.543C27.9442 44.0862 28.3249 43.7054 28.8579 43.7054C29.3909 43.7054 29.7716 44.0862 29.7716 44.543V46.142C29.6955 46.5988 29.3148 46.9796 28.8579 46.9796Z"
      :fill="iconColor"
    />
    <path
      d="M26.1168 46.9796C25.66 46.9796 25.2031 46.5988 25.2031 46.142V44.543C25.2031 44.0862 25.5838 43.7054 26.1168 43.7054C26.5737 43.7054 27.0305 44.0862 27.0305 44.543V46.142C26.9544 46.5988 26.5737 46.9796 26.1168 46.9796Z"
      :fill="iconColor"
    />
    <path
      d="M31.6752 46.9796C31.2183 46.9796 30.7615 46.5988 30.7615 46.142V44.543C30.7615 44.0862 31.1422 43.7054 31.6752 43.7054C32.2082 43.7054 32.5889 44.0862 32.5889 44.543V46.142C32.5889 46.5988 32.2082 46.9796 31.6752 46.9796Z"
      :fill="iconColor"
    />
    <path
      d="M34.4924 46.9796C34.0356 46.9796 33.5787 46.5988 33.5787 46.142V44.543C33.5787 44.0862 33.9594 43.7054 34.4924 43.7054C34.9493 43.7054 35.4062 44.0862 35.4062 44.543V46.142C35.4062 46.5988 34.9493 46.9796 34.4924 46.9796Z"
      :fill="iconColor"
    />
  </svg>
</template>
