<script>
import IconAppStore from "@/assets/apple-app-store.svg";
import IconGooglePlay from "@/assets/google-play.svg";
import IconVk from "@/assets/vk1.svg";
import IconOk from "@/assets/odnoklassniki.svg";
import IconInstagram from "@/assets/instagram1.svg";
import IconYoutube from "@/assets/youtube.svg";
import IconMoreLogo from "@/assets/LogoMore.svg";

export default {
  setup() {
    return {
      IconAppStore,
      IconGooglePlay,
      IconVk,
      IconOk,
      IconInstagram,
      IconYoutube,
      IconMoreLogo,
    };
  },
};
</script>
<template>
  <footer class="footer">
    <div class="footer__wrapper">
      <div class="copyright">
        <span>© ГАУ РБ "ДИРЕКЦИЯ СПОРТИВНЫХ СООРУЖЕНИЙ</span>
      </div>
      <div class="apps-badgets">
        <span>Скачать приложение:</span>
        <div class="app-icons">
          <a href="#">
            <img src="../assets/apple-app-store.png" alt="dss app ios" />
          </a>
          <a href="#">
            <img src="../assets/google-play.png" alt="dss app android" />
          </a>
        </div>
      </div>
      <div class="socials">
        <a href="#" class="social-item">
          <img src="../assets/vk1.png" alt="vk icon" />
        </a>
        <a href="#" class="social-item">
          <img src="../assets/odnoklassniki1.png" alt="od icon" />
        </a>
        <a href="#" class="social-item">
          <img src="../assets/instagram1.png" alt="instagram icon" />
        </a>
        <a href="#" class="social-item">
          <img src="../assets/youtube1.png" alt="youtube icon" />
        </a>
      </div>
      <div class="shield">
        <a href="https://more-studio.ru" target="_blank">
          <img src="../assets/LogoMore.png" alt="more-studio logo" />
        </a>
      </div>
    </div>
  </footer>
</template>

<style lang="sass">
@import "../styles/scss/_mixins.scss"

.footer
  &__wrapper
    @include d-flex(row, space-between, center, null, null, null)
    padding: $default-padding*1.35 $default-padding*1.25
    font-weight: 500
    @media only screen and (max-width: $sizes-lg - $default-padding*2)
      flex-wrap: wrap
      gap: $default-padding 0px
      & div
        width: 50%
        @media (max-width: $sizes-xs)
          width: 100%
    @media (max-width: $sizes-md)
      gap: $default-padding*1.5 0
    & .copyright
      font-size: $base-font-size*.9
      @media only screen and (max-width: $sizes-xl)
        font-size: $base-font-size*.75
      @media only screen and (max-width: $sizes-lg - $default-padding*2)
        order: 2
      @media (max-width: $sizes-sm)
        order: 0
        width: 90%
        margin: auto
        text-align: center
    & .apps-badgets
      @include d-flex(row, space-between, center, null, null, $default-padding*2)
      @media only screen and (max-width: $sizes-lg - $default-padding*2)
        order: 0
      @media (max-width: $sizes-sm - $default-padding*6)
        width: 100%
      & span
        @media (min-width: $sizes-lg - $default-padding*2) and (max-width: $sizes-xl)
          display: none
        @media (max-width: $sizes-lg - $default-padding*2)
          font-size: $base-font-size*.75
        @media (max-width: $sizes-md)
          display: none
      & .app-icons
        width: 100%
        @media (max-width: $sizes-sm)
          display: flex
        & a
          @media (max-width: $sizes-sm)
            flex: 1
            text-align: center
        & img
          @media (max-width: $sizes-sm - $default-padding*6)
            width: 50%
    & .socials
      @include d-flex(row, space-between, center, null, null, $default-padding*1.2)
      @media only screen and (max-width: $sizes-lg - $default-padding*2)
        order: 1
        justify-content: flex-end
        gap: $default-padding
      @media (max-width: $sizes-sm - $default-padding*6)
        width: 100%
        justify-content: space-between
    & .shield
      @media only screen and (max-width: $sizes-lg - $default-padding*2)
        text-align: end
        order: 3
      @media (max-width: $sizes-sm)
        width: 100%
        text-align: center
  & img
    transition: all .4s
    transition-delay: .15s
  & a
    &:hover img
      transform: translateY(-($default-padding*.4))
      opacity: .75
</style>
