<script>
export default {
  name: "icon-contact",
  props: {
    iconColor: {
      type: String,
      default: "#333333",
    },
  },
  setup() {},
};
</script>
<template>
  <svg
    width="61"
    height="60"
    viewBox="0 0 61 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M43 20.7857C43 22.6429 42.5636 24.3571 41.8364 25.9286C39.8727 29.8571 31.0727 42 31.0727 42C31.0727 42 22.4182 30 20.3818 26.2143C19.5091 24.5714 19 22.7143 19 20.7857C19 14.2857 24.3818 9 31 9C37.6909 9 43 14.2857 43 20.7857ZM41 21C41 22.5758 40.6364 24.0303 40.0303 25.3636C38.3939 28.697 31.0606 39 31.0606 39C31.0606 39 23.8485 28.8182 22.1515 25.6061C21.4242 24.2121 21 22.6364 21 21C21 15.4848 25.4848 11 31 11C36.5758 11 41 15.4848 41 21ZM36 21C36 23.7614 33.7614 26 31 26C28.2386 26 26 23.7614 26 21C26 18.2386 28.2386 16 31 16C33.7614 16 36 18.2386 36 21ZM34 21C34 22.6569 32.6569 24 31 24C29.3431 24 28 22.6569 28 21C28 19.3431 29.3431 18 31 18C32.6569 18 34 19.3431 34 21ZM40 32H47L53 48H9L15 32H22V34H16.5L12 46H50L45.5 34H40V32ZM53 51V49H9V51H53Z"
      :fill="iconColor"
    />
  </svg>
</template>
