<script>
export default {
  name: "icon-event",
  props: {
    iconColor: {
      type: String,
      default: "#333333",
    },
  },
  setup() {},
};
</script>
<template>
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M47.5 11.8H43.3V11.1C43.3 9.945 42.355 9 41.2 9C40.045 9 39.1 9.945 39.1 11.1V11.8H32.1V11.1C32.1 9.945 31.155 9 30 9C28.845 9 27.9 9.945 27.9 11.1V11.8H20.9V11.1C20.9 9.945 19.955 9 18.8 9C17.645 9 16.7 9.945 16.7 11.1V11.8H12.5C10.5633 11.8 9 13.3633 9 15.3V47.5C9 49.4367 10.5633 51 12.5 51H47.5C49.4367 51 51 49.4367 51 47.5V15.3C51 13.3633 49.4367 11.8 47.5 11.8ZM40.5 11.1C40.5 10.715 40.815 10.4 41.2 10.4C41.585 10.4 41.9 10.715 41.9 11.1V15.3C41.9 15.685 41.585 16 41.2 16C40.815 16 40.5 15.685 40.5 15.3V11.1ZM29.3 11.1C29.3 10.715 29.615 10.4 30 10.4C30.385 10.4 30.7 10.715 30.7 11.1V15.3C30.7 15.685 30.385 16 30 16C29.615 16 29.3 15.685 29.3 15.3V11.1ZM18.1 11.1C18.1 10.715 18.415 10.4 18.8 10.4C19.185 10.4 19.5 10.715 19.5 11.1V15.3C19.5 15.685 19.185 16 18.8 16C18.415 16 18.1 15.685 18.1 15.3V11.1ZM12.5 13.2H16.7V15.3C16.7 16.455 17.645 17.4 18.8 17.4C19.955 17.4 20.9 16.455 20.9 15.3V13.2H27.9V15.3C27.9 16.455 28.845 17.4 30 17.4C31.155 17.4 32.1 16.455 32.1 15.3V13.2H39.1V15.3C39.1 16.455 40.045 17.4 41.2 17.4C42.355 17.4 43.3 16.455 43.3 15.3V13.2H47.5C48.655 13.2 49.6 14.145 49.6 15.3V20.2H10.4V15.3C10.4 14.145 11.345 13.2 12.5 13.2ZM47.5 49.6H12.5C11.345 49.6 10.4 48.655 10.4 47.5V21.6H49.6V47.5C49.6 48.655 48.655 49.6 47.5 49.6Z"
      :fill="iconColor"
    />
    <path
      d="M46.1 26.5H43.3C42.915 26.5 42.6 26.815 42.6 27.2V30C42.6 30.385 42.915 30.7 43.3 30.7H46.1C46.485 30.7 46.8 30.385 46.8 30V27.2C46.8 26.815 46.485 26.5 46.1 26.5ZM45.4 29.3H44V27.9H45.4V29.3Z"
      :fill="iconColor"
    />
    <path
      d="M32.1 40.5H29.3C28.915 40.5 28.6 40.815 28.6 41.2V44C28.6 44.385 28.915 44.7 29.3 44.7H32.1C32.485 44.7 32.8 44.385 32.8 44V41.2C32.8 40.815 32.485 40.5 32.1 40.5ZM31.4 43.3H30V41.9H31.4V43.3Z"
      :fill="iconColor"
    />
    <path
      d="M25.1 26.5H22.3C21.915 26.5 21.6 26.815 21.6 27.2V30C21.6 30.385 21.915 30.7 22.3 30.7H25.1C25.485 30.7 25.8 30.385 25.8 30V27.2C25.8 26.815 25.485 26.5 25.1 26.5ZM24.4 29.3H23V27.9H24.4V29.3Z"
      :fill="iconColor"
    />
    <path
      d="M18.1 33.5H15.3C14.915 33.5 14.6 33.815 14.6 34.2V37C14.6 37.385 14.915 37.7 15.3 37.7H18.1C18.485 37.7 18.8 37.385 18.8 37V34.2C18.8 33.815 18.485 33.5 18.1 33.5ZM17.4 36.3H16V34.9H17.4V36.3Z"
      :fill="iconColor"
    />
    <path
      d="M25.1 33.5H22.3C21.915 33.5 21.6 33.815 21.6 34.2V37C21.6 37.385 21.915 37.7 22.3 37.7H25.1C25.485 37.7 25.8 37.385 25.8 37V34.2C25.8 33.815 25.485 33.5 25.1 33.5ZM24.4 36.3H23V34.9H24.4V36.3Z"
      :fill="iconColor"
    />
    <path
      d="M18.1 40.5H15.3C14.915 40.5 14.6 40.815 14.6 41.2V44C14.6 44.385 14.915 44.7 15.3 44.7H18.1C18.485 44.7 18.8 44.385 18.8 44V41.2C18.8 40.815 18.485 40.5 18.1 40.5ZM17.4 43.3H16V41.9H17.4V43.3Z"
      :fill="iconColor"
    />
    <path
      d="M25.1 40.5H22.3C21.915 40.5 21.6 40.815 21.6 41.2V44C21.6 44.385 21.915 44.7 22.3 44.7H25.1C25.485 44.7 25.8 44.385 25.8 44V41.2C25.8 40.815 25.485 40.5 25.1 40.5ZM24.4 43.3H23V41.9H24.4V43.3Z"
      :fill="iconColor"
    />
    <path
      d="M39.9051 29.3818L36.4051 28.8451L34.8301 25.4968C34.7134 25.2518 34.4684 25.1001 34.2001 25.1001C33.9317 25.1001 33.6867 25.2518 33.5701 25.4968L31.9951 28.8451L28.4951 29.3818C28.2384 29.4168 28.0167 29.6034 27.9351 29.8484C27.8534 30.0934 27.9117 30.3734 28.0984 30.5601L30.6534 33.1734L30.0467 36.8718C30.0001 37.1401 30.1167 37.3968 30.3384 37.5601C30.5601 37.7118 30.8401 37.7351 31.0734 37.6068L34.2001 35.8801L37.3267 37.6068C37.5601 37.7351 37.8517 37.7234 38.0734 37.5718C38.2951 37.4201 38.4001 37.1518 38.3651 36.8834L37.7584 33.1851L40.3134 30.5718C40.5001 30.3851 40.5584 30.1051 40.4767 29.8601C40.3834 29.6034 40.1617 29.4284 39.9051 29.3818ZM36.4984 32.4618C36.3467 32.6251 36.2767 32.8468 36.3117 33.0684L36.7434 35.6934L34.5384 34.4801C34.3284 34.3634 34.0717 34.3634 33.8617 34.4801L31.6567 35.6934L32.0884 33.0684C32.1234 32.8468 32.0534 32.6251 31.9017 32.4618L30.0584 30.5718L32.5784 30.1868C32.8117 30.1518 33.0101 30.0001 33.1034 29.7901L34.2001 27.4568L35.2967 29.7901C35.3901 30.0001 35.5884 30.1518 35.8217 30.1868L38.3417 30.5718L36.4984 32.4618Z"
      :fill="iconColor"
    />
    <path
      d="M46.1 33.5H43.3C42.915 33.5 42.6 33.815 42.6 34.2V37C42.6 37.385 42.915 37.7 43.3 37.7H46.1C46.485 37.7 46.8 37.385 46.8 37V34.2C46.8 33.815 46.485 33.5 46.1 33.5ZM45.4 36.3H44V34.9H45.4V36.3Z"
      :fill="iconColor"
    />
    <path
      d="M46.1 40.5H43.3C42.915 40.5 42.6 40.815 42.6 41.2V44C42.6 44.385 42.915 44.7 43.3 44.7H46.1C46.485 44.7 46.8 44.385 46.8 44V41.2C46.8 40.815 46.485 40.5 46.1 40.5ZM45.4 43.3H44V41.9H45.4V43.3Z"
      :fill="iconColor"
    />
    <path
      d="M39.1 40.5H36.3C35.915 40.5 35.6 40.815 35.6 41.2V44C35.6 44.385 35.915 44.7 36.3 44.7H39.1C39.485 44.7 39.8 44.385 39.8 44V41.2C39.8 40.815 39.485 40.5 39.1 40.5ZM38.4 43.3H37V41.9H38.4V43.3Z"
      :fill="iconColor"
    />
  </svg>
</template>
