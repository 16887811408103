<script>
export default {
  name: "icon-headquartes",
  props: {
    iconColor: {
      type: String,
      default: "#333333",
    },
  },
  setup() {},
};
</script>
<template>
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23.0228 20.4306C21.8029 18.3766 19.5622 17 17 17C13.134 17 10 20.134 10 24C10 27.866 13.134 31 17 31C20.6743 31 23.6874 28.1691 23.9772 24.5694C23.3564 23.5243 23 22.3038 23 21C23 20.8083 23.0077 20.6184 23.0228 20.4306ZM22 24C22 26.7614 19.7614 29 17 29C14.2386 29 12 26.7614 12 24C12 21.2386 14.2386 19 17 19C19.7614 19 22 21.2386 22 24Z"
      :fill="iconColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M36.0228 24.5694C36.6436 23.5243 37 22.3038 37 21C37 20.8083 36.9923 20.6184 36.9772 20.4306C38.1971 18.3766 40.4378 17 43 17C46.866 17 50 20.134 50 24C50 27.866 46.866 31 43 31C39.3257 31 36.3126 28.1691 36.0228 24.5694ZM48 24C48 26.7614 45.7614 29 43 29C40.2386 29 38 26.7614 38 24C38 21.2386 40.2386 19 43 19C45.7614 19 48 21.2386 48 24Z"
      :fill="iconColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8 38C8 33.5817 11.5817 30 16 30H18C19.908 30 21.6599 30.6679 23.0348 31.7827C22.4336 32.1251 21.8973 32.5685 21.449 33.0898C20.4733 32.4032 19.2837 32 18 32H16C12.6863 32 10 34.6863 10 38V43C10 43.5523 10.4477 44 11 44H20V46H11C9.34315 46 8 44.6569 8 43V38Z"
      :fill="iconColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M36.9652 31.7827C38.3401 30.6679 40.0921 30 42 30H44C48.4183 30 52 33.5817 52 38V43C52 44.6569 50.6569 46 49 46H40V44H49C49.5523 44 50 43.5523 50 43V38C50 34.6863 47.3137 32 44 32H42C40.7163 32 39.5267 32.4032 38.551 33.0898C38.1027 32.5685 37.5665 32.1251 36.9652 31.7827Z"
      :fill="iconColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M26 29C21.5817 29 18 32.5817 18 37V46C18 47.6569 19.3431 49 21 49H39C40.6569 49 42 47.6569 42 46V37C42 32.5817 38.4183 29 34 29H26ZM26 31C22.6863 31 20 33.6863 20 37V46C20 46.5523 20.4477 47 21 47H39C39.5523 47 40 46.5523 40 46V37C40 33.6863 37.3137 31 34 31H26Z"
      :fill="iconColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M30 30C34.9706 30 39 25.9706 39 21C39 16.0294 34.9706 12 30 12C25.0294 12 21 16.0294 21 21C21 25.9706 25.0294 30 30 30ZM30 28C33.866 28 37 24.866 37 21C37 17.134 33.866 14 30 14C26.134 14 23 17.134 23 21C23 24.866 26.134 28 30 28Z"
      :fill="iconColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M28 29C26.8954 29 26 29.8954 26 31V40C26 41.1046 26.8954 42 28 42H32C33.1046 42 34 41.1046 34 40V31C34 29.8954 33.1046 29 32 29H28ZM29 31C28.4477 31 28 31.4477 28 32V39C28 39.5523 28.4477 40 29 40H31C31.5523 40 32 39.5523 32 39V32C32 31.4477 31.5523 31 31 31H29Z"
      :fill="iconColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M26.6887 40.4485C26.4636 41.1411 26.6262 41.9323 27.1764 42.4826L28.4326 43.7388C29.2136 44.5198 30.48 44.5198 31.261 43.7388L32.7185 42.2813C33.2131 41.7867 33.3945 41.0975 33.2626 40.4601C32.965 41.2347 32.2156 41.8008 31.3513 41.8215L30.8316 41.834L30.6114 42.0543C30.2209 42.4448 29.5877 42.4448 29.1972 42.0543L29.0204 41.8776L28.5858 41.888C27.6591 41.9103 26.8948 41.2985 26.6887 40.4485Z"
      :fill="iconColor"
    />
  </svg>
</template>
