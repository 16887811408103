<script>
export default {
  name: "icon-sekcii",
  props: {
    iconColor: {
      type: String,
      default: "#333333",
    },
  },
  setup() {},
};
</script>
<template>
  <svg
    width="61"
    height="60"
    viewBox="0 0 61 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.4433 45.9137C16.7581 45.9137 16.0728 45.6853 15.4636 45.1523C14.8545 44.6193 14.3215 44.0863 13.7885 43.4772C13.2555 42.868 13.0271 42.1066 13.0271 41.2691C13.1032 40.4315 13.484 39.7462 14.0931 39.2132L20.7175 32.8173C17.4433 27.4112 19.0423 20.1777 24.8291 14.9239C27.951 12.1828 31.6819 10.6599 35.5652 10.6599C35.6413 10.6599 35.6413 10.6599 35.7175 10.6599C39.5246 10.6599 42.951 12.1066 45.3875 14.7716C47.8241 17.4366 48.9662 21.0152 48.5093 24.8223C48.0525 28.5533 46.2251 32.132 43.1794 34.8731C37.8494 39.7462 31.3012 40.9645 26.2758 38.0711L19.8037 44.8477C19.3469 45.4569 18.5855 45.8376 17.8241 45.9137C17.6718 45.9137 17.5195 45.9137 17.4433 45.9137ZM35.6413 12.4112C35.5652 12.4112 35.5652 12.4112 35.489 12.4112C32.0626 12.4112 28.7124 13.7817 26.0474 16.2183C20.7175 21.0914 19.2708 27.7919 22.621 32.4366C22.8494 32.8173 22.8494 33.2741 22.5449 33.5787L15.3875 40.5076C15.0829 40.7361 14.9307 41.0406 14.8545 41.4213C14.8545 41.7259 14.9307 42.1066 15.1591 42.335C15.6159 42.868 16.1489 43.401 16.6819 43.8579C16.9103 44.0863 17.2149 44.1624 17.5956 44.1624C17.9002 44.1624 18.2048 44.0102 18.4332 43.7056L25.4383 36.396C25.7428 36.0914 26.1997 36.0152 26.5804 36.2437C30.9966 39.1371 37.0119 38.0711 41.9611 33.5787C44.6261 31.1421 46.3012 27.9442 46.6819 24.5939C47.0626 21.3198 46.0728 18.198 44.0169 15.9137C41.9611 13.6295 38.9916 12.4112 35.6413 12.4112Z"
      :fill="iconColor"
    />
    <path
      d="M37.088 38.3756C36.8596 38.3756 36.6311 38.2995 36.4789 38.1472L20.7174 22.3858C20.4129 22.0812 20.4129 21.4721 20.7174 21.1675C21.022 20.8629 21.6311 20.8629 21.9357 21.1675L37.6971 36.9289C38.0017 37.2335 38.0017 37.8426 37.6971 38.1472C37.5449 38.2995 37.3164 38.3756 37.088 38.3756Z"
      :fill="iconColor"
    />
    <path
      d="M19.7277 44.3909C19.4992 44.3909 19.2708 44.3148 19.1185 44.1625L14.9307 39.9747C14.6261 39.6701 14.6261 39.0609 14.9307 38.7564C15.2353 38.4518 15.8444 38.4518 16.149 38.7564L20.3368 42.9442C20.6414 43.2488 20.6414 43.8579 20.3368 44.1625C20.1845 44.2386 19.9561 44.3909 19.7277 44.3909Z"
      :fill="iconColor"
    />
    <path
      d="M26.0474 37.8427C25.8189 37.8427 25.5905 37.7665 25.4382 37.6142L21.3265 33.5787C20.9458 33.2741 20.9458 32.665 21.3265 32.3604C21.6311 31.9797 22.2403 31.9797 22.5448 32.3604L26.6565 36.396C27.0372 36.7005 27.0372 37.3097 26.6565 37.6142C26.5042 37.7665 26.2758 37.8427 26.0474 37.8427Z"
      :fill="iconColor"
    />
    <path
      d="M29.6261 49.4163C28.484 49.4163 27.3418 48.9594 26.5804 48.1219C25.7429 47.2843 25.286 46.2183 25.286 45.0762C25.286 43.9341 25.7429 42.7919 26.5804 42.0305C27.418 41.1929 28.484 40.7361 29.6261 40.7361C30.7682 40.7361 31.9104 41.1929 32.6718 42.0305C34.3469 43.7056 34.3469 46.5229 32.6718 48.198C31.8342 48.9594 30.7682 49.4163 29.6261 49.4163ZM29.6261 42.4112C28.9408 42.4112 28.2556 42.7158 27.7987 43.1726C27.3418 43.6295 27.0373 44.3148 27.0373 45C27.0373 45.6853 27.3418 46.3706 27.7987 46.8275C28.7885 47.8173 30.4637 47.8173 31.4535 46.8275C32.4434 45.8376 32.4434 44.1625 31.4535 43.1726C30.9205 42.7158 30.3114 42.4112 29.6261 42.4112Z"
      :fill="iconColor"
    />
  </svg>
</template>
