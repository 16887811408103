<script>
export default {
  name: "icon-football",
  props: {
    iconColor: {
      type: String,
      default: "#333333",
    },
  },
  setup() {},
};
</script>
<template>
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M46.066 48.2741H13.5533C11.1168 48.2741 9.13708 46.2944 9.13708 43.8579V23.3757C9.13708 20.9391 11.1168 18.9594 13.5533 18.9594H46.066C48.5026 18.9594 50.4823 20.9391 50.4823 23.3757V43.8579C50.4823 46.2944 48.5026 48.2741 46.066 48.2741ZM13.5533 20.7107C12.0305 20.7107 10.8884 21.929 10.8884 23.3757V43.8579C10.8884 45.3807 12.1066 46.5229 13.5533 46.5229H46.066C47.5889 46.5229 48.731 45.3046 48.731 43.8579V23.3757C48.731 21.8528 47.5127 20.7107 46.066 20.7107H13.5533Z"
      :fill="iconColor"
    />
    <path
      d="M29.8477 41.2691C25.6599 41.2691 22.2335 37.8427 22.2335 33.6549C22.2335 29.467 25.6599 26.0406 29.8477 26.0406C34.0356 26.0406 37.4619 29.467 37.4619 33.6549C37.4619 37.8427 34.0356 41.2691 29.8477 41.2691ZM29.8477 27.7158C26.5736 27.7158 23.9848 30.3808 23.9848 33.5787C23.9848 36.8528 26.6498 39.4417 29.8477 39.4417C33.0457 39.4417 35.7107 36.7767 35.7107 33.5787C35.7107 30.3808 33.0457 27.7158 29.8477 27.7158Z"
      :fill="iconColor"
    />
    <path
      d="M29.8478 48.2741C29.3909 48.2741 28.9341 47.8934 28.9341 47.4366V19.797C28.9341 19.3401 29.3148 18.9594 29.8478 18.9594C30.3046 18.9594 30.7615 19.3401 30.7615 19.797V47.3604C30.6853 47.8934 30.3046 48.2741 29.8478 48.2741Z"
      :fill="iconColor"
    />
    <path
      d="M49.4925 39.8223H44.3148C43.858 39.8223 43.4011 39.4416 43.4011 38.9848V28.3249C43.4011 27.868 43.7818 27.4873 44.3148 27.4873H49.4925C49.9494 27.4873 50.4062 27.868 50.4062 28.3249V38.9848C50.4062 39.4416 50.0255 39.8223 49.4925 39.8223ZM45.1524 38.0711H48.5788V29.1624H45.1524V38.0711Z"
      :fill="iconColor"
    />
    <path
      d="M15.3046 39.8223H10.127C9.67011 39.8223 9.21326 39.4416 9.21326 38.9848V28.3249C9.21326 27.868 9.59397 27.4873 10.127 27.4873H15.3046C15.7615 27.4873 16.2183 27.868 16.2183 28.3249V38.9848C16.2183 39.4416 15.8376 39.8223 15.3046 39.8223ZM11.0407 38.0711H14.4671V29.1624H11.0407V38.0711Z"
      :fill="iconColor"
    />
    <path
      d="M49.6447 25.5076H47.132C46.6751 25.5076 46.2183 25.1269 46.2183 24.6701C46.2183 24.2132 46.599 23.8325 47.132 23.8325H49.6447C50.1015 23.8325 50.5584 24.2132 50.5584 24.6701C50.4822 25.1269 50.1015 25.5076 49.6447 25.5076Z"
      :fill="iconColor"
    />
    <path
      d="M49.6447 43.8579H47.132C46.6751 43.8579 46.2183 43.4772 46.2183 43.0203C46.2183 42.5634 46.599 42.1827 47.132 42.1827H49.6447C50.1015 42.1827 50.5584 42.5634 50.5584 43.0203C50.4822 43.4772 50.1015 43.8579 49.6447 43.8579Z"
      :fill="iconColor"
    />
    <path
      d="M12.6397 25.5076H10.127C9.67011 25.5076 9.21326 25.1269 9.21326 24.6701C9.21326 24.2132 9.59397 23.8325 10.127 23.8325H12.6397C13.0965 23.8325 13.5534 24.2132 13.5534 24.6701C13.4772 25.1269 13.0965 25.5076 12.6397 25.5076Z"
      :fill="iconColor"
    />
    <path
      d="M12.6397 43.8579H10.127C9.67011 43.8579 9.21326 43.4772 9.21326 43.0203C9.21326 42.5634 9.59397 42.1827 10.127 42.1827H12.6397C13.0965 42.1827 13.5534 42.5634 13.5534 43.0203C13.4772 43.4772 13.0965 43.8579 12.6397 43.8579Z"
      :fill="iconColor"
    />
    <path
      d="M22.8427 15.7614C21.5483 15.7614 20.4061 14.6954 20.4061 13.3249C20.4061 11.9543 21.4721 10.9645 22.8427 10.9645C24.1371 10.9645 25.2792 12.0305 25.2792 13.401C25.2792 14.7716 24.1371 15.7614 22.8427 15.7614ZM22.8427 12.7157C22.462 12.7157 22.1574 13.0203 22.1574 13.401C22.1574 13.7817 22.462 14.0102 22.8427 14.0102C23.2234 14.0102 23.528 13.7056 23.528 13.3249C23.528 12.9442 23.1472 12.7157 22.8427 12.7157Z"
      :fill="iconColor"
    />
    <path
      d="M29.8477 15.7614C28.5533 15.7614 27.4111 14.6954 27.4111 13.3249C27.4111 11.9543 28.4771 10.9645 29.8477 10.9645C31.1421 10.9645 32.2842 12.0305 32.2842 13.401C32.2842 14.7716 31.1421 15.7614 29.8477 15.7614ZM29.8477 12.7157C29.467 12.7157 29.1624 13.0203 29.1624 13.401C29.1624 13.7817 29.467 14.0102 29.8477 14.0102C30.1523 14.0102 30.4568 13.7056 30.4568 13.401C30.4568 13.0965 30.1523 12.7157 29.8477 12.7157Z"
      :fill="iconColor"
    />
    <path
      d="M36.8528 15.7614C35.5584 15.7614 34.4163 14.6954 34.4163 13.3249C34.4163 11.9543 35.4823 10.9645 36.8528 10.9645C38.1472 10.9645 39.2894 12.0305 39.2894 13.401C39.2894 14.7716 38.1472 15.7614 36.8528 15.7614ZM36.8528 12.7157C36.4721 12.7157 36.1675 13.0203 36.1675 13.401C36.1675 13.7817 36.4721 14.0102 36.8528 14.0102C37.2335 14.0102 37.5381 13.7056 37.5381 13.3249C37.5381 12.9442 37.2335 12.7157 36.8528 12.7157Z"
      :fill="iconColor"
    />
  </svg>
</template>
