<script>
export default {
  name: "icon-hotel",
  props: {
    iconColor: {
      type: String,
      default: "#333333",
    },
  },
  setup() {},
};
</script>
<template>
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.1543 13H12.1543H47.399H48.399V14V25.3607H50.0535H51.0535V26.3607V34.7848V35.7848V35.792V36.792V41.1876V42.1876H50.3157V45.2162V46.2162H49.3157H42.3579H41.3579V45.2162V42.1876H18.1883V45.2162V46.2162H17.1883H10.2305H9.2305V45.2162V42.1876H8.5V41.1876V36.792V35.792V35.7848V34.7848V26.3607V25.3607H9.5H11.1543V14V13ZM49.0535 34.7848H10.5V27.3607H49.0535V34.7848ZM10.5 36.792V40.1876H17.1883H18.1883H41.3579H42.3579L49.0535 40.1876V36.792H10.5ZM13.1543 15V25.3606H13.4399V20.1622V19.1622H14.4399H27.2561H28.2561V20.1622V25.3606H31.5649V20.1622V19.1622H32.5649H45.3812H46.3812V20.1622V25.3606H46.399V15H13.1543ZM43.3579 42.1876H48.3157V44.2162H43.3579V42.1876ZM11.2305 42.1876V44.2162H16.1883V42.1876H11.2305ZM15.4399 21.1622V21.7391H17.3926V23.2579H15.4399V24.0173H17.3926V24.7333H15.4399V25.3534H26.2561V21.1622H15.4399ZM33.5649 25.3534V21.1622H44.3812V25.3534H33.5649ZM33.9011 23.2579V21.7391H35.9011V23.2579H33.9011ZM33.9011 24.0173V24.7333H35.9011V24.0173H33.9011ZM11.5522 31.6332V28.6389H13.5522V31.6332H11.5522ZM11.5522 32.87V34.0417H13.5522V32.87H11.5522Z"
      :fill="iconColor"
    />
  </svg>
</template>
