import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import(/* webpackChunkName "home" */ "@/views/Home.vue"),
    meta: {
      layout: "AppLayoutHome",
    },
  },
  {
    path: "/about",
    name: "About",
    component: () => import(/* webpackChunkName "about" */ "@/views/About.vue"),
    meta: {
      layout: "AppLayoutCategory",
    },
  },
  {
    path: "/test",
    name: "Test",
    component: () => import(/* webpackChunkName "home" */ "@/views/Test.vue"),
  },
  {
    path: "/category/:slug",
    name: "Category",
    component: () =>
      import(/* webpackChunkName "home" */ "@/views/Category.vue"),
  },
  {
    path: "/page/:type/:slug",
    name: "Page",
    component: () => import(/* webpackChunkName "home" */ "@/views/Page.vue"),
    meta: {
      layout: "AppLayoutPage",
    },
  },
  {
    path: "/page/:type/reception",
    name: "Reception",
    component: () =>
      import(/* webpackChunkName "home" */ "@/views/Reception.vue"),
    meta: {
      layout: "AppLayoutPage",
    },
  },
  {
    path: "/page/search",
    name: "Search",
    component: () => import(/* webpackChunkName "home" */ "@/views/Search.vue"),
    meta: {
      layout: "AppLayoutSearch",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
