<script>
export default {
  name: "MobileMenuItem",
  inject: ["toggleMobileMenu"],
  props: {
    menu: {
      type: Object,
      reqired: true,
    },
    index: {
      type: Number,
      reqired: true,
    },
  },
};
</script>
<template>
  <details :open="index === 0">
    <summary>
      <router-link
        @click="toggleMobileMenu"
        :to="{
          name: 'Category',
          params: { slug: menu.type },
        }"
        >{{ menu.title }}</router-link
      >
    </summary>
    <div class="links-wrap">
      <router-link
        class="mobile_menu-link"
        v-for="(link, i) in menu.links"
        :key="i"
        @click="toggleMobileMenu"
        :to="{
          name: 'Page',
          params: { type: menu.type, slug: link.id },
        }"
        >{{ link.name }}</router-link
      >
    </div>
  </details>
</template>

<style lang="scss" scoped>
details {
  summary {
    list-style: none;
    display: block;
    background-color: #f4f4f4;
    padding: 15px 10px;
    position: relative;
    cursor: pointer;
    color: #027fc7;
    font-size: 24px;
    font-weight: 500;
    text-transform: uppercase;
    &::-webkit-details-marker {
      display: none;
    }
    &:before {
      display: flex;
      justify-content: center;
      align-items: center;
      content: " \02C7";
      font-family: FontAwesome;
      font-size: 90%;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: 30px;
      color: #fff;
    }
  }
  > :not(summary) {
    padding: 10px;
    background-color: #fff;
    transform: scaleY(0);
    transform-origin: top;
    transition: transform 300ms;
  }
  &[open] summary:before {
    content: " \02C6";
  }
  &[open] > :not(summary) {
    transform: scaleY(1);
  }
  & .mobile_menu-link {
    padding: 20px;
    color: #027fc7;
  }
}

.links-wrap {
  display: flex;
  flex-direction: column;
}
</style>
