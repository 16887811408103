<script>
export default {
  name: "icon-tournaments",
  props: {
    iconColor: {
      type: String,
      default: "#333333",
    },
  },
  setup() {},
};
</script>
<template>
  <svg
    width="61"
    height="60"
    viewBox="0 0 61 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M32.7667 39.9645H27.9088C27.4063 39.9645 26.9037 39.5457 26.9037 39.0431V32.5939C24.8936 32.1751 23.3022 30.5838 22.9672 28.4898L21.0408 15.0888C21.0408 14.8376 21.0408 14.5025 21.292 14.335C21.4596 14.0838 21.7108 14 21.9621 14H38.7134C38.9646 14 39.2997 14.0838 39.4672 14.335C39.6347 14.5863 39.7184 14.8376 39.7184 15.0888L37.6245 28.4061C37.2895 30.5 35.6981 32.0914 33.688 32.5102V38.9594C33.688 39.5457 33.2692 39.9645 32.7667 39.9645ZM28.8301 38.0381H31.7616V31.6726C31.7616 31.1701 32.1804 30.7513 32.7667 30.7513C34.2743 30.7513 35.5306 29.6624 35.7819 28.1548L37.6245 15.9264H23.0509L24.8936 28.1548C25.1448 29.6624 26.4012 30.7513 27.9088 30.7513C28.4113 30.7513 28.9139 31.1701 28.9139 31.6726V38.0381H28.8301Z"
      :fill="iconColor"
    />
    <path
      d="M36.6194 43.4823H24.056C23.5534 43.4823 23.0509 43.0635 23.0509 42.5609C23.0509 40.0482 25.0611 38.0381 27.5737 38.0381H33.1017C35.6144 38.0381 37.6245 40.0482 37.6245 42.5609C37.6245 43.0635 37.122 43.4823 36.6194 43.4823ZM25.1448 41.5559H35.4468C35.1118 40.6345 34.1905 39.9645 33.1017 39.9645H27.5737C26.4849 39.9645 25.5636 40.6345 25.1448 41.5559Z"
      :fill="iconColor"
    />
    <path
      d="M39.8023 46.9999H20.8734C20.3708 46.9999 19.8683 46.5812 19.8683 46.0786C19.8683 43.5659 21.8784 41.5558 24.3911 41.5558H36.2845C38.7972 41.5558 40.8074 43.5659 40.8074 46.0786C40.8074 46.5812 40.3048 46.9999 39.8023 46.9999ZM21.9622 45.0735H38.6297C38.2109 44.1522 37.2896 43.4822 36.2008 43.4822H24.3911C23.3023 43.4822 22.381 44.1522 21.9622 45.0735Z"
      :fill="iconColor"
    />
    <path
      d="M37.1221 29.7462C36.6196 29.7462 36.1171 29.3274 36.1171 28.8248C36.1171 28.3223 36.5358 27.9035 37.1221 27.9035C39.1323 27.9035 40.9749 26.7309 41.8125 24.8883L43.6551 20.7005C43.8227 20.3654 43.7389 20.1142 43.5714 19.7792C43.4039 19.5279 43.1526 19.3604 42.8176 19.3604H17.8582C17.5232 19.2766 17.2719 19.4441 17.1044 19.6954C16.9369 19.9467 16.8531 20.2817 17.0206 20.6167L18.8633 24.8045C19.7008 26.6472 21.5435 27.8198 23.5536 27.8198C24.0562 27.8198 24.5587 28.2385 24.5587 28.7411C24.5587 29.2436 24.1399 29.6624 23.5536 29.6624C20.7897 29.6624 18.277 27.9873 17.1044 25.4746L15.2617 21.2868C14.843 20.3654 14.9267 19.3604 15.513 18.6066C16.0155 17.8528 16.9369 17.3502 17.8582 17.3502H42.8176C43.8227 17.3502 44.6602 17.8528 45.2465 18.6066C45.749 19.4441 45.8328 20.4492 45.4978 21.2868L43.6551 25.4746C42.3988 28.071 39.8861 29.7462 37.1221 29.7462Z"
      :fill="iconColor"
    />
    <path
      d="M32.7666 32.6777H31.6778C31.1753 32.6777 30.6727 32.2589 30.6727 31.7564C30.6727 31.2539 31.0915 30.8351 31.6778 30.8351H32.7666C33.2692 30.8351 33.7717 31.2539 33.7717 31.7564C33.688 32.1752 33.2692 32.6777 32.7666 32.6777Z"
      :fill="iconColor"
    />
  </svg>
</template>
