<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import { provide, ref } from "vue";

export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
  setup() {
    const isMobileMenu = ref(false);

    const toggleMobileMenu = () => {
      isMobileMenu.value = !isMobileMenu.value;
      if (isMobileMenu.value) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    };

    provide("isMobileMenu", isMobileMenu);
    provide("toggleMobileMenu", toggleMobileMenu);
  },
};
</script>

<template>
  <Header />
  <main>
    <AppLayout>
      <router-view />
    </AppLayout>
  </main>
  <Footer />
</template>

<style lang="scss"></style>
