<script>
export default {
  name: "icon-docs",
  props: {
    iconColor: {
      type: String,
      default: "#333333",
    },
  },
  setup() {},
};
</script>
<template>
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M51.2668 50.5334H48.3335V30C48.3322 28.7856 47.3479 27.8013 46.1335 27.8V23.4C46.1322 22.1856 45.1479 21.2013 43.9335 21.2V16.8C43.9322 15.5855 42.9479 14.6013 41.7335 14.6V10.2C41.7322 8.98553 40.7479 8.00129 39.5335 8H20.4667C19.2522 8.00129 18.268 8.98553 18.2667 10.2V14.6C17.0522 14.6013 16.068 15.5855 16.0667 16.8V21.2C14.8522 21.2013 13.868 22.1856 13.8667 23.4V27.8C12.6522 27.8013 11.668 28.7856 11.6667 30V50.5334H8.73331C8.32828 50.5334 8 50.8617 8 51.2667C8 51.6717 8.32828 52.0001 8.73331 52.0001H51.2667C51.6717 52.0001 52 51.6718 52 51.2668C52 50.8618 51.6718 50.5334 51.2668 50.5334ZM19.7333 10.2C19.7336 9.79515 20.0618 9.46696 20.4666 9.4667H39.5334C39.9383 9.46696 40.2664 9.79515 40.2667 10.2V14.6H29.1926C28.8441 12.8929 27.3424 11.6667 25.6 11.6667C23.8576 11.6667 22.356 12.8929 22.0075 14.6H19.7334V10.2H19.7333ZM27.6649 14.6H23.5352C23.8416 13.7217 24.6699 13.1333 25.6001 13.1333C26.5303 13.1333 27.3586 13.7217 27.6649 14.6ZM17.5333 16.8C17.5336 16.3951 17.8618 16.067 18.2666 16.0667H41.7334C42.1383 16.067 42.4664 16.3952 42.4667 16.8V21.2H26.9926C26.6441 19.4929 25.1424 18.2667 23.4 18.2667C21.6576 18.2667 20.156 19.4929 19.8075 21.2H17.5334V16.8H17.5333ZM25.4649 21.2H21.3352C21.6416 20.3217 22.4699 19.7333 23.4001 19.7333C24.3303 19.7333 25.1586 20.3217 25.4649 21.2ZM15.3333 23.4C15.3336 22.9951 15.6618 22.667 16.0666 22.6667H43.9334C44.3382 22.667 44.6664 22.9952 44.6667 23.4V27.8H24.7926C24.4441 26.0929 22.9424 24.8667 21.2 24.8667C19.4576 24.8667 17.956 26.0929 17.6075 27.8H15.3333V23.4ZM23.2649 27.8H19.1352C19.4416 26.9218 20.2699 26.3333 21.2001 26.3333C22.1303 26.3333 22.9586 26.9218 23.2649 27.8ZM46.8668 50.5334H13.1333V30C13.1336 29.5952 13.4618 29.267 13.8666 29.2667H46.1334C46.5383 29.267 46.8664 29.5952 46.8667 30V50.5334H46.8668Z"
      :fill="iconColor"
    />
    <path
      d="M20.4667 41C22.8967 41 24.8667 39.03 24.8667 36.6C24.8667 34.1699 22.8967 32.2 20.4667 32.2C18.0366 32.2 16.0667 34.1699 16.0667 36.6C16.0693 39.0289 18.0376 40.9973 20.4667 41ZM20.4667 33.6666C22.0867 33.6666 23.4 34.9799 23.4 36.6C23.4 38.22 22.0867 39.5333 20.4667 39.5333C18.8466 39.5333 17.5333 38.22 17.5333 36.6C17.5349 34.9805 18.8472 33.6682 20.4667 33.6666Z"
      :fill="iconColor"
    />
    <path
      d="M43.2001 32.2H27.8001C27.3951 32.2 27.0668 32.5282 27.0668 32.9333C27.0668 33.3383 27.3951 33.6666 27.8001 33.6666H43.2001C43.6051 33.6666 43.9334 33.3383 43.9334 32.9333C43.9334 32.5282 43.6051 32.2 43.2001 32.2Z"
      :fill="iconColor"
    />
    <path
      d="M43.2001 35.8667H27.8001C27.3951 35.8667 27.0668 36.1951 27.0668 36.6C27.0668 37.005 27.3951 37.3333 27.8001 37.3333H43.2001C43.6051 37.3333 43.9334 37.0049 43.9334 36.6C43.9334 36.195 43.6051 35.8667 43.2001 35.8667Z"
      :fill="iconColor"
    />
    <path
      d="M43.2001 39.5334H27.8001C27.3951 39.5334 27.0668 39.8617 27.0668 40.2668C27.0668 40.6718 27.3951 41.0001 27.8001 41.0001H43.2001C43.6051 41.0001 43.9334 40.6719 43.9334 40.2668C43.9334 39.8618 43.6051 39.5334 43.2001 39.5334Z"
      :fill="iconColor"
    />
    <path
      d="M43.2 43.2002H16.8C16.3949 43.2002 16.0667 43.5285 16.0667 43.9335C16.0667 44.3385 16.3949 44.6668 16.8 44.6668H43.2C43.605 44.6668 43.9333 44.3385 43.9333 43.9335C43.9333 43.5285 43.605 43.2002 43.2 43.2002Z"
      :fill="iconColor"
    />
    <path
      d="M43.2 46.8667H16.8C16.3949 46.8667 16.0667 47.1951 16.0667 47.6C16.0667 48.0049 16.3949 48.3333 16.8 48.3333H43.2C43.605 48.3333 43.9333 48.005 43.9333 47.6C43.9333 47.195 43.605 46.8667 43.2 46.8667Z"
      :fill="iconColor"
    />
  </svg>
</template>
