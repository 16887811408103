<template>
  <div class="layout home-layout">
    <slot />
  </div>
</template>

<script>
export default {
  name: "AppLayoutHome",
};
</script>
<style lang="sass"></style>
