<script>
export default {
  name: "icon-search",
  props: {
    iconColor: {
      type: String,
      default: "#808080",
    },
  },
  setup() {},
};
</script>
<template>
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.565 24.935L21.465 19.85C23.1105 17.7537 24.0033 15.165 24 12.5C24 10.1266 23.2962 7.80655 21.9776 5.83316C20.6591 3.85977 18.7849 2.3217 16.5922 1.41345C14.3995 0.505199 11.9867 0.267559 9.65892 0.730582C7.33115 1.1936 5.19295 2.33649 3.51472 4.01472C1.83649 5.69295 0.693605 7.83115 0.230582 10.1589C-0.232441 12.4867 0.00519937 14.8995 0.913451 17.0922C1.8217 19.2849 3.35977 21.1591 5.33316 22.4776C7.30655 23.7962 9.62663 24.5 12 24.5C14.665 24.5033 17.2537 23.6105 19.35 21.965L24.435 27.065C24.5745 27.2056 24.7404 27.3172 24.9231 27.3933C25.1059 27.4695 25.302 27.5087 25.5 27.5087C25.698 27.5087 25.8941 27.4695 26.0769 27.3933C26.2597 27.3172 26.4256 27.2056 26.565 27.065C26.7056 26.9256 26.8172 26.7597 26.8933 26.5769C26.9695 26.3941 27.0087 26.198 27.0087 26C27.0087 25.802 26.9695 25.6059 26.8933 25.4231C26.8172 25.2404 26.7056 25.0745 26.565 24.935ZM3.00001 12.5C3.00001 10.72 3.52785 8.97992 4.51678 7.49987C5.50571 6.01983 6.91132 4.86628 8.55585 4.18509C10.2004 3.5039 12.01 3.32567 13.7558 3.67294C15.5016 4.0202 17.1053 4.87737 18.364 6.13604C19.6226 7.39472 20.4798 8.99836 20.8271 10.7442C21.1743 12.49 20.9961 14.2996 20.3149 15.9442C19.6337 17.5887 18.4802 18.9943 17.0001 19.9832C15.5201 20.9722 13.78 21.5 12 21.5C9.61306 21.5 7.32387 20.5518 5.63604 18.864C3.94822 17.1761 3.00001 14.887 3.00001 12.5Z"
      :fill="iconColor"
    />
  </svg>
</template>
