<script>
export default {
  name: "icon-training",
  props: {
    iconColor: {
      type: String,
      default: "#333333",
    },
  },
  setup() {},
};
</script>
<template>
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.4721 39.9746H18.1218C16.5228 39.9746 15.3046 38.6802 15.3046 37.1573V22.7665C15.3046 21.2436 16.599 19.9492 18.198 19.9492H21.5482C23.0711 19.9492 24.3655 21.2436 24.3655 22.7665V37.0812C24.3655 38.6802 23.0711 39.9746 21.4721 39.9746ZM18.198 21.7005C17.5888 21.7005 17.132 22.1573 17.132 22.7665V37.0812C17.132 37.6903 17.5888 38.1472 18.198 38.1472H21.5482C22.1574 38.1472 22.6142 37.6903 22.6142 37.0812V22.7665C22.6142 22.1573 22.1574 21.7005 21.5482 21.7005H18.198Z"
      :fill="iconColor"
    />
    <path
      d="M14.7716 37.0051H14.0102C12.7158 37.0051 11.726 36.0152 11.726 34.7208V25.1269C11.726 23.8325 12.7158 22.8427 14.0102 22.8427H14.8478C16.1422 22.8427 17.132 23.8325 17.132 25.1269V34.7208C17.0559 36.0152 16.0661 37.0051 14.7716 37.0051ZM14.0102 24.5939C13.7056 24.5939 13.4772 24.8223 13.4772 25.1269V34.7208C13.4772 35.0254 13.7056 35.2538 14.0102 35.2538H14.8478C15.1523 35.2538 15.3808 35.0254 15.3808 34.7208V25.1269C15.3808 24.8223 15.1523 24.5939 14.8478 24.5939H14.0102Z"
      :fill="iconColor"
    />
    <path
      d="M42.5635 39.9746H39.2133C37.6143 39.9746 36.396 38.6802 36.396 37.1573V22.7665C36.396 21.1675 37.6904 19.9492 39.2133 19.9492H42.5635C44.1625 19.9492 45.3808 21.2436 45.3808 22.7665V37.0812C45.4569 38.6802 44.1625 39.9746 42.5635 39.9746ZM39.2894 21.7005C38.6803 21.7005 38.2234 22.1573 38.2234 22.7665V37.0812C38.2234 37.6903 38.6803 38.1472 39.2894 38.1472H42.6396C43.2488 38.1472 43.7056 37.6903 43.7056 37.0812V22.7665C43.7056 22.1573 43.2488 21.7005 42.6396 21.7005H39.2894Z"
      :fill="iconColor"
    />
    <path
      d="M46.7513 37.0051H45.9138C44.6194 37.0051 43.6295 36.0152 43.6295 34.7208V25.1269C43.6295 23.8325 44.6194 22.8427 45.9138 22.8427H46.7513C48.0458 22.8427 49.0356 23.8325 49.0356 25.1269V34.7208C49.0356 36.0152 48.0458 37.0051 46.7513 37.0051ZM45.9899 24.5939C45.6854 24.5939 45.4569 24.8223 45.4569 25.1269V34.7208C45.4569 35.0254 45.6854 35.2538 45.9899 35.2538H46.8275C47.1321 35.2538 47.3605 35.0254 47.3605 34.7208V25.1269C47.3605 24.8223 47.1321 24.5939 46.8275 24.5939H45.9899Z"
      :fill="iconColor"
    />
    <path
      d="M12.5635 32.2081H11.3452C10.1269 32.2081 9.13708 31.2183 9.13708 30C9.13708 28.7817 10.1269 27.7919 11.3452 27.7919H12.5635C13.0203 27.7919 13.4772 28.1726 13.4772 28.6294C13.4772 29.0863 13.0965 29.467 12.5635 29.467H11.3452C11.0406 29.467 10.8884 29.6954 10.8884 29.9238C10.8884 30.2284 11.1168 30.3807 11.3452 30.3807H12.5635C13.0203 30.3807 13.4772 30.7614 13.4772 31.2183C13.4772 31.8274 13.0965 32.2081 12.5635 32.2081Z"
      :fill="iconColor"
    />
    <path
      d="M37.3097 28.5533H23.4518C22.9949 28.5533 22.5381 28.1726 22.5381 27.7157C22.5381 27.2589 22.9188 26.8782 23.4518 26.8782H37.3097C37.7665 26.8782 38.2234 27.2589 38.2234 27.7157C38.1472 28.0964 37.7665 28.5533 37.3097 28.5533Z"
      :fill="iconColor"
    />
    <path
      d="M49.4163 32.2081H48.198C47.7412 32.2081 47.2843 31.8274 47.2843 31.3705C47.2843 30.9137 47.665 30.533 48.198 30.533H49.4163C49.7209 30.533 49.8731 30.3046 49.8731 30.0761C49.8731 29.7716 49.6447 29.6193 49.4163 29.6193H48.198C47.7412 29.6193 47.2843 29.2386 47.2843 28.7817C47.2843 28.3249 47.665 27.9442 48.198 27.9442H49.4163C50.6346 27.9442 51.6244 28.934 51.6244 30.1523C51.6244 31.2183 50.6346 32.2081 49.4163 32.2081Z"
      :fill="iconColor"
    />
    <path
      d="M37.3097 33.1218H23.4518C22.9949 33.1218 22.5381 32.7411 22.5381 32.2843C22.5381 31.8274 22.9188 31.4467 23.4518 31.4467H37.3097C37.7665 31.4467 38.2234 31.8274 38.2234 32.2843C38.1472 32.7411 37.7665 33.1218 37.3097 33.1218Z"
      :fill="iconColor"
    />
    <path
      d="M41.4974 39.9746C41.0406 39.9746 40.5837 39.5939 40.5837 39.137V35.4822C40.5837 35.0254 40.9645 34.6447 41.4974 34.6447C41.9543 34.6447 42.4111 35.0254 42.4111 35.4822V39.137C42.4111 39.5939 42.0304 39.9746 41.4974 39.9746Z"
      :fill="iconColor"
    />
    <path
      d="M19.1879 25.3553C18.6549 25.3553 18.2742 24.9746 18.2742 24.5178V20.7868C18.2742 20.3299 18.6549 19.9492 19.1879 19.9492C19.6447 19.9492 20.1016 20.3299 20.1016 20.7868V24.5178C20.0254 24.9746 19.6447 25.3553 19.1879 25.3553Z"
      :fill="iconColor"
    />
  </svg>
</template>
